import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useAuthContext } from '../../context/AuthContext'
// Styles
import {
  ImageWrapper,
  StyledSearch,
  SearchItemWrapper,
  StyledLink,
  StyledSpan,
  SearchItemInfo,
  StyledImage,
  TitleContainer,
} from './Header.styles'

const initialState = {
  isLoading: false,
  results: [],
  value: '',
}

// TODO - Fix image sizing, put Image in it's own container to normalize spacing
const resultRenderer = ({
  uid,
  title,
  image,
  price,
  pv,
  url_key,
  is_ambassador,
  sku 
}) => {
  return (
    <SearchItemWrapper key={uid} as={StyledLink} to={`/products/${url_key}`}>
      <ImageWrapper data-qa={`searchImage-${sku}`}>
        <StyledImage src={image} />
      </ImageWrapper>
      <SearchItemInfo>
        <TitleContainer data-qa={`searchTitle-${sku}`}>
          <StyledSpan isTitle>{title}</StyledSpan>
        </TitleContainer>
        <div>
          <StyledSpan data-qa={`searchPrice-${sku}`} price>{`$${price}`}</StyledSpan>
          {is_ambassador ? <StyledSpan data-qa={`searchPV-${sku}`} pv>PV: {`${pv}`}</StyledSpan> : null}
        </div>
      </SearchItemInfo>
    </SearchItemWrapper>
  )
}

export default function SearchMenu({ products, callback }) {
  const [{ isLoading, results, value }, setState] = useState(initialState)
  const [data, setData] = useState(null)

  const { isAmbassador } = useAuthContext()

  useEffect(() => {
    if (!products.length) return
    ;(() => {
      const makeData = products.map(
        ({ name, uid, url_key, price_range, image, pv, sku }) => {
          const finalPrice = price_range?.maximum_price?.final_price?.value
          return {
            uid,
            url_key,
            pv,
            image: image.url,
            title: name,
            price: finalPrice ? finalPrice.toFixed(2) : '-',
            is_ambassador: isAmbassador ? 'true' : undefined,
            sku,
          }
        }
      )
      setData(makeData)
    })()
    return null
  }, [products])

  const handleResultSelect = (e, { result }) => {
    setState({ value: result.name })
    callback()
  }

  const handleSearchChange = (e, { value }) => {
    setState({ isLoading: true, value })

    if (value.length < 1) {
      setState({ isLoading: false })
      return
    }

    setTimeout(() => {
      const filtered = data.filter(product =>
        product.title.toLowerCase().includes(value.toLowerCase())
      )
      setState({ isLoading: false, results: filtered })
    }, 500)
  }

  return (
    <StyledSearch
      fluid
      minCharacters={2}
      loading={isLoading}
      onResultSelect={handleResultSelect}
      onSearchChange={handleSearchChange}
      resultRenderer={resultRenderer}
      results={results}
      value={value}
      autoFocus
      data-qa="searchInput"
    />
  )
}

SearchMenu.propTypes = {
  products: PropTypes.array.isRequired,
}
