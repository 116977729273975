import styled, { css } from 'styled-components'
import { Icon, Image } from 'semantic-ui-react'
import { Primary } from '..'
import { Link } from 'gatsby'

export const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  position: absolute;
  backdrop-filter: blur(5px) contrast(0.8);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
`

export const Main = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: 90vw;
  max-width: 400px;
  background: black;
  padding: 2em 0;
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
`

export const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: ${props => (props.padded ? '1em' : '0')};
  ${props =>
    props.info &&
    css`
      padding: 0 2em 2em 2em;
    `}
`

export const StyledSpan = styled.span`
  color: ${({ theme }) => theme.colors.white};
  margin: 5px 0;
  ${props =>
    props.bolder &&
    css`
      font-weight: bolder;
      font-size: 1.4em;
    `}
  ${props =>
    props.bold &&
    css`
      font-weight: bold;
      margin-bottom: 1em;
    `}
  ${props =>
    props.emphasized &&
    css`
      font-style: italic;
    `}
`

export const IgniteImage = styled(Image)`
  &&& {
    width: 100%;
    height: auto;
    max-width: 400px;
    margin-bottom: 2em;
  }
`

export const QLogo = styled(Image)`
  &&& {
    width: 50%;
    height: auto;
    max-width: 200px;
  }
`

export const CloseButton = styled(Primary)`
  box-shadow: none !important;
`

export const StyledLink = styled(Link)`
  font-weight: bold;
  font-size: 1.5em;
  margin-top: 10px;
`

export const InfoIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.skyBlue};
`