import { gql } from 'graphql-request'

// ------------------------------ Q SERVICES ------------------------------

// possible values returned for loginStatus: "VERIFICATION_NEEDED", "NOT_AN_AMBASSADOR", "SUCCESS"
export const LOGIN_USER = gql`
  mutation LoginUser($ambassadorOnly: Boolean!) {
    loginUser(ambassadorOnly: $ambassadorOnly) {
      loginStatus
      associate {
        legacyAssociateId
        associateId
        associateType
        firstName
        lastName
        displayName
        emailAddress
        dS_Username
        dS_UsersRecordNumber
        fireBaseId
        profileUrl
      }
    }
  }
`

// possible values returned for status: "NOT_FOUND", "NOT_AN_AMBASSADOR", 'CALL_SUPPORT', "SUCCESS"
export const DIRECT_SCALE_INFO = gql`
  mutation DirectScaleInfo($ambassadorOnly: Boolean!, $userName: String!) {
    # userName === dS_Username
    directScaleInfo(ambassadorOnly: $ambassadorOnly, userName: $userName) {
      status
      associate {
        associateId
        dS_Username
        legacyAssociateId
        uniqueEmailAddress
        emailAddress
        primaryPhoneNumber
        secondaryPhoneNumber
      }
    }
  }
`

// possible values returned for status: 'VERIFICATION_COMPLETE', 'MESSAGE_SENT', 'FAILURE'
export const LOGIN_VALIDATION_PROCESS = gql`
  mutation LoginValidationProcess(
    $method: ValidationMethod!
    $loginName: String!
    $verificationInfo: String!
  ) {
    loginValidationProcess(
      input: {
        method: $method
        loginName: $loginName
        verificationInfo: $verificationInfo
      }
    ) {
      status
      associate {
        associateId
        legacyAssociateId
      }
    }
  }
`

// possible values returned for status: 'VERIFICATION_COMPLETE', 'CAN_NOT_FIND_TOKEN'
export const CONFIRM_ACCESS_CODE = gql`
  mutation LoginValidationToken($loginName: String!, $accessCode: String!) {
    loginValidationToken(
      input: { loginName: $loginName, accessCode: $accessCode }
    ) {
      status
      associate {
        associateId
        legacyAssociateId
      }
    }
  }
`

// ------------------------------ PAYMENT/CHECKOUT ------------------------------

export const GET_CARDS_ON_FILE = gql`
  mutation GetCardsOnFile($associateId: Int!) {
    nexioCreditCardsForAssociate(associateId: $associateId) {
      associateId
      dateCreated
      dateModified
      companyName
      creditCardGuid
      creditCardType
      lastFourDigits
      expirationDate
      active
    }
  }
`

export const GET_ONE_TIME_NEXIO_TOKEN = gql`
  mutation OneTimeToken {
    nexioOneTimeTokenIframe {
      token
    }
  }
`

export const SAVE_CARD_TO_ASSOCIATE = gql`
  mutation SaveCardData($associateId: Int!, $cardData: SaveCardDataInInput!) {
    nexioSaveCardData(associateId: $associateId, cardData: $cardData) {
      value
    }
  }
`

export const NEXIO_POST_TRANSACTION = gql`
  mutation NexioPostTransaction(
    $magentoOrderId: String!
    $creditCardGuid: String!
  ) {
    nexioPostTransaction(
      magentoOrderId: $magentoOrderId
      creditCardGuid: $creditCardGuid
    ) {
      value
    }
  }
`
