import React from 'react'
import { Label } from 'semantic-ui-react'

const ShoppingCartIcon = ({ cartCount = 0, children }) => {
  return (
    <div style={{ color: 'white', position: 'relative' }}>
      {cartCount ? (
        <Label color="red" circular size="tiny" content={cartCount} floating />
      ) : null}
      {children}
    </div>
  )
}

export default ShoppingCartIcon
