import styled from 'styled-components'
import { Image } from 'semantic-ui-react'

export const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  margin: 1rem 1.5rem;
`

interface ContainerProps {
  direction: 'column' | 'row'
  justify: string
  align: string
  padding: string
  maxWidth: string
  textAlign: string
}

export const Container = styled.div<Partial<ContainerProps>>`
  display: flex;
  flex-direction: ${props => props.direction || 'row'};
  justify-content: ${props => props.justify || 'center'};
  align-items: ${props => props.align || 'center'};
  width: 100%;
  max-width: ${props => props.maxWidth || 'none'};
  padding: ${props => props.padding || '0.5rem'};
  text-align: ${props => props.textAlign};

  span {
    margin-bottom: 5px;
  }
`

export const QLogo = styled(Image)`
  &&& {
    width: ${props => props.width ?? '50px'};
    height: auto;
  }
`

export const SignInTitle = styled.span`
  font-size: 2em;
  font-weight: 100;
  margin: 0.5em 0;
`

export const UseEmail = styled.div`
  width: 100%;
  max-width: 500px;
  text-align: center;
  border-bottom: ${props => `1px solid ${props.theme.colors.dimGray}`};
  line-height: 0.1em;
  margin: 10px 0 20px;
  color: ${props => props.theme.colors.dimGray};

  span {
    background: ${({ theme }) => theme.colors.white};
    padding: 0 10px;
    font-size: 1.2rem;
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 3rem;
`

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`

export const StyledInput = styled.input`
  padding: 10px;
  width: ${props => props.width || '100%'};
  max-width: 500px;
  border: 1px solid #222;
  border-radius: 10px;
  margin-bottom: 1rem;
  font-size: 16px;

  ::placeholder {
    color: ${props => props.theme.colors.silver};
  }
  :focus {
    border: 1px solid ${props => props.theme.colors.metallicSeaweed};
  }
`

interface StyledSelectProps {
  width: string
}
export const StyledSelect = styled('select')<Partial<StyledSelectProps>>`
  padding: 10px;
  width: ${props => props.width || '100%'};
  max-width: 500px;
  border: 1px solid #222;
  border-radius: 10px;
  margin-bottom: 1rem;
  font-size: 16px;
`

export const StyledSpan = styled.span`
  color: ${({ theme }) => theme.colors.sapphireBlue};
  margin: 10px;
  cursor: pointer;
`

export const ErrorMsg = styled.span`
color: ${({ theme }) => theme.colors.carnelian};
  align-self: flex-start;
  margin-bottom: 5px;
`

export const Icon = styled(Image)`
  &&& {
    width: ${props => props.width ?? '50px'};
    height: auto;
    cursor: pointer;
  }
`

export const PaymentInfoContainer = styled(Container)`
  padding: 0 10px;
  flex-direction: column;
  @media ${({ theme }) => theme.device.sm} {
    padding: 0 25%;
  }
`
