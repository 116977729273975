import React, { useState } from 'react'
import { Magento } from '../services/magento'

const useOrderHistory = () => {
  const [ordersData, setOrdersData] = useState([])
  const [ordersPageInfo, setOrdersPageInfo] = useState(null)
  const [isLoadingOrders, setIsLoadingOrders] = useState(true)
  const [isLoadingMoreOrders, setIsLoadingMoreOrders] = useState(false)

  const handleGetPageDataAndOrders = async () => {
    // only run if no data in array
    if (ordersData.length) return

    let orderPages = await Magento.User.getOrdersPageInfo().then(
      ({ customer }) => {
        const pageInfo = customer.orders.page_info
        setOrdersPageInfo(pageInfo)
        return pageInfo
      }
    )

    // bail out if orderPages = 0 meaning there are no orders
    if (!orderPages.total_pages) {
      setIsLoadingOrders(false)
      return
    }

    let initOrders = await Magento.User.orderHistory({
      currentPage: orderPages.total_pages,
    }).then(({ customer }) => customer.orders)

    const pageSize = initOrders.page_info.page_size
    const totalPages = initOrders.page_info.total_pages
    const currentPage = initOrders.page_info.current_page

    if (totalPages === currentPage) {
      setOrdersData(initOrders.items.sort((a, b) => b.number - a.number))
      setOrdersPageInfo(initOrders.page_info)
      setIsLoadingOrders(false)
      return
    }

    if (initOrders.items.length < pageSize) {
      await Magento.User.orderHistory({
        currentPage: orderPages - 1,
      }).then(({ customer }) => {
        const newOrders = customer.orders.items
        const newPage = customer.orders.page_info
        setOrdersData(removeDuplicatesAndSort(initOrders, newOrders))
        setOrdersPageInfo(newPage)
      })
    } else {
      setOrdersData(initOrders.items.sort((a, b) => b.number - a.number))
      setOrdersPageInfo(initOrders.page_info)
    }

    setIsLoadingOrders(false)
  }

  const removeDuplicatesAndSort = (orders, newOrders) => {
    const orderIds = new Set(orders.map(({ number }) => number))
    const combinedOrders = [
      ...orders,
      ...newOrders.filter(({ number }) => !orderIds.has(number)),
    ]
    return combinedOrders.sort((a, b) => b.number - a.number)
  }

  const handleGetOrders = async pageNumber => {
    await Magento.User.orderHistory({
      currentPage: pageNumber,
    }).then(({ customer }) => {
      const newOrdersData = customer.orders.items
      const pageInfo = customer.orders.page_info
      setOrdersData(removeDuplicatesAndSort(ordersData, newOrdersData))
      setOrdersPageInfo(pageInfo)
      setIsLoadingMoreOrders(false)
    })
  }

  const handleGetNextPageOrders = () => {
    setIsLoadingMoreOrders(true)
    handleGetOrders(ordersPageInfo?.current_page - 1)
  }

  return [
    handleGetNextPageOrders,
    handleGetPageDataAndOrders,
    ordersData,
    ordersPageInfo,
    isLoadingOrders,
    isLoadingMoreOrders,
  ]
}

export default useOrderHistory
