import styled, { css } from 'styled-components'
import { Image } from 'semantic-ui-react'
import igniteLoad from '../../images/ignite-loading-screen.jpg'

export const LoadingWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 60px);
  display: flex;
  justify-content: center;
  align-items: center;

  ${props =>
    props.isEvent &&
    css`
      background-image: ${`url(${igniteLoad})`};
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    `}
`

export const Logo = styled(Image)`
  &&& {
    height: 240px;
    width: 200px;
    margin-top: 10em;
    margin-left: 15px;

    @media only screen and (orientation: landscape) {
      margin-top: 10%;
    }
  }
`

export const dimmerStyle = {
  display: 'flex',
  height: '100vh',
  justifyContent: 'flex-start',
  alignItems: 'center',
}