import styled, { css } from 'styled-components'
import { Image } from 'semantic-ui-react'

export const OrderItemViewContainer = styled.div``

export const OrderHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #d8d8d8;
  padding: 10px 15px;

  @media ${({ theme }) => theme.device.sm} {
    padding: 15px 20px;
  }
`

export const HeaderLeftContent = styled.div`
  display: flex;
  align-items: center;
  width: 50%;
`

export const HeaderRightContent = styled.div`
  width: 50%;
  text-align: right;
`

export const OrderHeaderSpan = styled.span``

export const OrderStatusImg = styled(Image)`
  width: 24px;
  height: 24px;

  @media ${({ theme }) => theme.device.sm} {
    width: 30px;
    height: 30px;
  }
`

export const ProductItem = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #979797;
  margin: 0 20px;
  padding: 10px 0;

  @media ${({ theme }) => theme.device.sm} {
    margin: 0 20px;
  }
`

export const ProductImage = styled(Image)`
  &&& {
    width: auto;
    max-width: 50px;
    height: auto;
    max-height: 50px;

    @media ${({ theme }) => theme.device.sm} {
      max-width: 100px;
      max-height: 100px;
    }
  }
`

export const ProductCostInfoWrapper = styled.span``

export const ProductCostInfo = styled.div`
  min-width: 120px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${({ theme }) => theme.device.sm} {
    min-width: 160px;
  }
`

interface StyledSpanProps {
  bold: boolean
  gray: boolean
  lineThru: boolean
  fontSize: string
  padding: string
  width: string
  minWidth: string
  textAlign: string
  small: boolean
  big: boolean
  discount: boolean
}

export const StyledSpan = styled.span<Partial<StyledSpanProps>>`
  font-size: ${props => (props.big ? '16px' : props.small ? '10px' : '12px')};
  color: ${props => (props.gray ? props.theme.colors.dimGray : props.theme.colors.black)};
  padding: ${props => props.padding || '0'};
  width: ${props => props.width || 'unset'};
  min-width: ${props => props.minWidth || 'unset'};
  text-align: ${props => props.textAlign || 'left'};

  @media ${({ theme }) => theme.device.sm} {
    font-size: ${props => (props.big ? '21px' : props.small ? '14px' : '17px')};
  }

  ${props =>
    props.bold &&
    css`
      font-weight: bolder;
    `}

  ${props =>
    props.lineThru &&
    css`
      text-decoration: line-through;
      color: ${({ theme }) => theme.colors.silver};
    `}
  ${props =>
    props.discount &&
    css`
      color: ${({ theme }) => theme.colors.carnelian} !important;
    `}
`

export const ProductTitle = styled(StyledSpan)`
  width: 100%;
  margin-left: 10px;
`

export const ProductCount = styled(StyledSpan)`
  min-width: 40px;
  text-align: center;
  margin-right: 2em;

  @media ${({ theme }) => theme.device.sm} {
    margin-right: 3em;
  }
`

export const OrderCostWrapper = styled.div`
  margin: 0 20px;
  padding: 10px 0;
`

export const OrderCostItemTitle = styled(StyledSpan)`
  min-width: 60px;

  @media ${({ theme }) => theme.device.sm} {
    min-width: 80px;
  }
`

export const OrderCostInfo = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 3px;
  &:last-child {
    margin-bottom: 0;
  }

  @media ${({ theme }) => theme.device.sm} {
    margin-bottom: 10px;
  }
`

export const StatusIcon = styled(Image)`
  &&& {
    height: 30px;
    width: 30px;
  }
`
