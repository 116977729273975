import styled, { css } from 'styled-components'
import { Image, Button } from 'semantic-ui-react'
import { Primary as PrimaryButton } from '..'

export const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: 100%;
`

export const Container = styled.div`
  display: flex;
  flex-flow: ${props => (props.column ? 'column nowrap' : 'row nowrap')};
  justify-content: ${props => props.justify};
  align-items: ${props => props.align};
  width: 100%;
  padding: ${props => props.padding};
  margin: ${props => props.margin};
  max-width: ${props => props.maxWidth};

  ul > li {
    margin-bottom: 0.5em;
  }
`

export const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  flex: 1 0 40%;
  width: 100%;
`

export const DescContainer = styled.div`
  padding: 0 2em 2em 2em;
  max-width: 600px;

  p {
    margin: 0;
  }
`

export const Title = styled.span`
  font-size: 1.7em;
  font-weight: bold;
  margin: 0.3em 0;
  padding: ${props => props.padding};
  color: ${props =>
    props.white ? props.theme.colors.white : props.theme.colors.black};
`

export const StyledImage = styled(Image)`
  &&& {
    width: 100%;
    max-width: 600px;
    height: auto;
  }
`

export const StyledButton = styled(Button)`
  &&& {
    display: flex;
    flex: 1 0 auto;
    border: 1px solid black;
    background: white;
    padding: 1em;

    :hover {
      color: rgba(0, 0, 0, 0.6);
    }

    :active {
      color: rgba(0, 0, 0, 0.8);
    }

    @media ${({ theme }) => theme.device.sm} {
      :hover {
        color: rgba(0, 0, 0, 0.8) !important;
      }

      :active,
      :focus {
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }
`

export const MinusButton = styled(StyledButton)`
  &&& {
    border-left: none;
    border-radius: 0;
    margin: 0;
    margin-left: 1em;
    justify-content: flex-end;
  }
`

export const PlusButton = styled(StyledButton)`
  &&& {
    border-left: none;
    border-right: none;
    border-radius: 0;
    justify-content: flex-start;
    margin: 0 1em 0 0;
  }
`

export const StyledSpan = styled.span`
  font-size: 1.5em;

  ${props =>
    props.isTitle &&
    css`
      font-weight: bold;
      margin-bottom: 1em;
    `}

  ${props =>
    props.lineThru &&
    css`
      font-size: 1.2em;
      margin-right: 5px;
      opacity: 0.6;
      text-decoration: line-through;
      color: ${({ theme }) => theme.colors.dimGray};
    `}

  ${props =>
    props.count &&
    css`
      font-size: 1.5em;
      font-weight: bold;
      margin: 10px;
    `}

  ${props =>
    props.bold &&
    css`
      font-weight: bold;
    `}

  ${props =>
    props.pv &&
    css`
      font-size: 1.2em;
      font-weight: bold;
      color: ${({ theme }) => theme.colors.dimGray};
    `}
`

export const TabWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2em;
  max-width: 600px;
  border-top: 1px solid #c1c1c1;
`

export const TabItem = styled.div`
  font-size: ${({ theme }) => theme.fontSize.default};
  font-weight: bolder;
  cursor: pointer;
  position: relative;
  padding-bottom: 2px;
  margin: 0;
  border-bottom: 2px solid white;

  @media ${({ theme }) => theme.device.sm} {
    font-size: ${({ theme }) => theme.fontSize.big};
  }

  &&&.active {
    border-bottom: 2px solid ${({ theme }) => theme.colors.metallicSeaweed};
  }
`

export const TabItemSplit = styled.span`
  width: 2px;
  height: 20px;
  margin-top: 1px;
  background-color: ${({ theme }) => theme.colors.black};
`

export const TabItemContent = styled.div``

export const AddButton = styled(PrimaryButton)`
  &&& {
    text-transform: uppercase;
  }
`
