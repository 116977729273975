import React from 'react'
import { Icon } from 'semantic-ui-react'

// Utils & Services
import { usePrismic } from '../../context/PrismicContext'

// Styles
import { Wrapper, Container, Logo } from './Footer.styles'

const Footer = () => {
  const {
    prismicData: { prismicFooter },
  } = usePrismic()

  const { logo, facebook, instagram, linkedin, twitter, copyright } =
    prismicFooter

  return (
    <Wrapper>
      <Container>
        <Container>
          <Logo src={logo.url} />
        </Container>
        <Container row>
          <a data-qa="footerFacebook" href={facebook.url} target={facebook.target} rel="noreferrer">
            <Icon name="facebook f" size="big" />
          </a>
          <a data-qa="footerInstagram" href={instagram.url} target={instagram.target} rel="noreferrer">
            <Icon name="instagram" size="big" />
          </a>
          <a data-qa="footerTwitter" href={twitter.url} target={twitter.target} rel="noreferrer">
            <Icon name="twitter" size="big" />
          </a>
          <a data-qa="footerLinkedin" href={linkedin.url} target={linkedin.target} rel="noreferrer">
            <Icon name="linkedin" size="big" />
          </a>
        </Container>
        <p>{copyright[0].text}</p>
      </Container>
    </Wrapper>
  )
}

export default Footer
