import React from 'react'
import { Dimmer, Loader } from 'semantic-ui-react'
import qLogo from '../../images/white-q.png'

import {
  LoadingWrapper,
  Logo,
  dimmerStyle,
} from './Loading.styles'

const Loading = ({ loading, message, showLogo = true, showBg = false }) => (
  <LoadingWrapper data-qa="loading" isEvent={showBg}>
    <Dimmer style={dimmerStyle} active={loading}>
      {showLogo && <Logo src={qLogo} />}
      <Loader style={{ marginTop: '100px' }} size="huge">
        {message}
      </Loader>
    </Dimmer>
  </LoadingWrapper>
)

export default Loading
