import React, { useState } from 'react'
import { Link } from 'gatsby'
import { Image } from 'semantic-ui-react'
// Context
import { useAuthContext } from '../../context/AuthContext'
import { useCart } from '../../context/CartContext'
// Styles
import {
  ButtonsWrapper,
  CardInfoContainer,
  CardWrapper,
  Container,
  Count,
  MinusButton,
  PlusButton,
  RemoveButton,
  StyledSpan,
  Title,
  OutOfStockOverlay,
  RotatedText,
} from './CartProductCard.styles'
import { usePrismic } from '../../context/PrismicContext'
import { OUT_OF_STOCK } from '../../utils/cartHelpers'

const CardPricingDetails = ({
  basePrice = 0,
  finalPrice = 0,
  savings = 0,
  pvPoints = 0,
  isAmbassador = false,
  isPc = false,
  product = {}
}) => {
  const {sku } = product
  const hasDiscount = finalPrice < basePrice
  
  return (
    <Container column padding="1em 0 0 0" align="flex-end">
      <Container>
        {(isAmbassador || isPc) && hasDiscount && (
          <StyledSpan data-qa={`priceLine-${sku}`} lineThru>{`$${basePrice.toFixed(2)}`}</StyledSpan>
        )}
        <StyledSpan data-qa={`priceBold-${sku}`} bold>{`$${finalPrice.toFixed(2)}`}</StyledSpan>
      </Container>
      <Container align="flex-end" padding="10px 0">
        {(isAmbassador || isPc) && (
          <>
            {hasDiscount && (
              <StyledSpan data-qa={`priceSavings-${sku}`} savings>{`(You save $${savings.toFixed(
                2
              )})`}</StyledSpan>
            )}
            {isAmbassador ? (
              <StyledSpan data-qa={`pvBold-${sku}`} bold>{`PV: ${pvPoints}`}</StyledSpan>
            ) : null}
          </>
        )}
      </Container>
    </Container>
  )
}

const CartProductCard = ({ item }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [updateAdd, setUpdateAdd] = useState(false)
  const [updateSub, setUpdateSub] = useState(false)

  const {
    prismicData: {
      prismicCartPage: { out_of_stock },
    },
  } = usePrismic()

  const { product, quantity, prices, uid } = item

  const configOption = item?.configurable_options?.[0] ?? null

  const { name, url_key, price_range, pv, image, stock_status, sku } = product

  const { isAmbassador, isPc } = useAuthContext()
  const { manageCart } = useCart()

  const handleUpdateQuantity = async type => {
    if (!type) setUpdateSub(true)
    else setUpdateAdd(true)

    await manageCart.updateCartItems(item, type)

    if (!type) setUpdateSub(false)
    else setUpdateAdd(false)
  }

  const basePrice = price_range?.maximum_price?.regular_price?.value ?? ''
  const baseTotal = basePrice * quantity
  const finalPrice = prices?.price?.value ?? ''
  const finalTotal = finalPrice * quantity
  const savings = baseTotal - finalTotal
  const pvPoints = pv * quantity

  const handleRemoveFromCart = () => {
    setIsLoading(true)
    manageCart.removeItemFromCart(uid, name)
  }

  const isOutOfStock = stock_status === OUT_OF_STOCK

  return (
    <CardWrapper>
      <OutOfStockOverlay hidden={!isOutOfStock}>
        <RotatedText>{out_of_stock[0].text}</RotatedText>
      </OutOfStockOverlay>
      <Title data-qa={`title-${sku}`}>
        <span >{`${name}`}</span>
        {!!configOption && (
          <span>{`${configOption.option_label} - ${configOption.value_label}`}</span>
        )}
      </Title>
      <Image
        data-qa={`image-${sku}`}
        as={Link}
        to={`/products/${url_key}`}
        src={image.url}
        size="small"
        style={{ paddingTop: '2em' }}        
      />
      <CardInfoContainer>
        <CardPricingDetails
          basePrice={baseTotal}
          finalPrice={finalTotal}
          savings={savings}
          pvPoints={pvPoints}
          isAmbassador={isAmbassador}
          isPc={isPc}
          product={product}
        />
        <ButtonsWrapper hidden={isOutOfStock}>
          <MinusButton
            loading={updateSub}
            disabled={updateSub}
            onClick={() => handleUpdateQuantity(false)}
            data-qa={`minusButton-${sku}`}
          >
            <span style={{ fontSize: '2em' }}>-</span>
          </MinusButton>
          <Count data-qa={`itemCount-${sku}`}>{quantity}</Count>
          <PlusButton
            loading={updateAdd}
            disabled={updateAdd}
            onClick={handleUpdateQuantity}
            data-qa={`plusButton-${sku}`}
          >
            <span style={{ fontSize: '2em' }}>+</span>
          </PlusButton>
        </ButtonsWrapper>
        <RemoveButton
          loading={isLoading}
          disabled={isLoading}
          onClick={handleRemoveFromCart}
          data-qa={`removeButton-${sku}`}
        >
          REMOVE
        </RemoveButton>
      </CardInfoContainer>
    </CardWrapper>
  )
}

export default CartProductCard
