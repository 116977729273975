import styled from 'styled-components'
import { Primary } from '..'

export const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  background: ${({ theme }) => theme.colors.turquoiseGreen};
  padding: 1em;
  align-items: center;
`

export const Title = styled.div`
  font-size: 1.4em;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1em;
`

export const StyledText = styled.span`
`

export const ListContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
`

export const ListItem = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.5em;
`

export const StartSavingButton = styled(Primary)`
  &&& {
    background-color: ${({ theme }) => theme.colors.turquoiseGreen};
    color: #fffef9;
    margin-bottom: 0.3em;
    width: 100%;
    border: 1px solid ${({ theme }) => theme.colors.black};
    color: ${({ theme }) => theme.colors.black};
    text-transform: uppercase;
    padding: 0.7em;

    :active,
    :focus,
    :hover {
      box-shadow: none;
      border: none;
      background-color: ${({ theme }) => theme.colors.powderBlue};
    }
  }
`