import React from 'react'
import { Image } from 'semantic-ui-react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination, Zoom } from 'swiper'

import 'swiper/css'
import 'swiper/css/zoom'
import 'swiper/css/pagination'

import './styles.css'

SwiperCore.use([Pagination, Zoom])

const ProductImages = ({ mediaGallery = [], image }) => {
  const renderGallery = gallery =>
    gallery.map(slide => (
      <SwiperSlide key={`${slide.url}`} className="product-image">
        <Image data-qa="image" src={slide.url} centered />
      </SwiperSlide>
    ))

  return (
    <>
      <Swiper
        style={{
          '--swiper-pagination-color': '#007985',
        }}
        zoom={true}
        pagination={{
          clickable: true,
        }}
        slidesPerView={1}
        className="product-images-swiper"
        data-qa="swiperNoSwiping"
      >
        {mediaGallery.length > 0 ? (
          renderGallery(mediaGallery)
        ) : (
          <SwiperSlide>
            <Image src={image?.url} />
          </SwiperSlide>
        )}
      </Swiper>
    </>
  )
}

export default ProductImages
