import React, { useState } from 'react'
import toast from 'react-hot-toast'
// Components
import { ProductCard, ProductImages, PageHeader } from '..'
import ConfigProduct from './ConfigurableProduct'
// Styles
import {
  Wrapper,
  Container,
  PlusButton,
  MinusButton,
  CardsContainer,
  DescContainer,
  StyledSpan,
  TabWrapper,
  TabItem,
  TabItemSplit,
  TabItemContent,
  AddButton,
} from './ProductSummary.styles'
// Context
import { useAuthContext } from '../../context/AuthContext'
import { usePrismic } from '../../context/PrismicContext'
import { useCart } from '../../context/CartContext'

// TODO - Find out what is supposed to happen on click of more details. Show a div? Go to product?
// const RelatedProduct = ({ name }) => (
//   <Container column padding="0.7em 0">
//     <span style={{ fontSize: '1.5em' }}>{name}</span>
//     <span style={{ fontSize: '1.2em', color: 'gray' }}>+ More Details</span>
//   </Container>
// )

const ProductSummary = ({
  name,
  price_range,
  sku,
  image,
  media_gallery,
  related_products,
  ingredient,
  suggested_use,
  description,
  configurable_options = null,
  configurable_product_options_selection = null,
  items,
  pv,
  stock_status,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [selectedOption, setSelectedOption] = useState(null)

  const {
    prismicData: { prismicProductDetailPage },
  } = usePrismic()
  const { isUserAuthenticated, isAmbassador, isPc } = useAuthContext()
  const { manageCart } = useCart()

  const {
    details,
    ingredients,
    suggested_use: suggestedUseText,
    sign_in_error,
    coming_soon,
    add,
    you_may_also_like,
    out_of_stock,
  } = prismicProductDetailPage

  const tabList =
    items && items.length > 0
      ? [details[0].text]
      : [details[0].text, ingredients[0].text, suggestedUseText[0].text]
  const [count, setCount] = useState(1)
  const [activeTab, setActiveTab] = useState(tabList[0])

  const finalPrice = price_range?.maximum_price?.final_price?.value
  const regularPrice = price_range?.maximum_price?.regular_price?.value

  const handleSetCount = type => {
    if (type === 'plus') return setCount(count + 1)
    if (count === 1 && type === 'minus') return
    setCount(count - 1)
  }

  const handleAddToCart = async () => {
    if (!isUserAuthenticated) {
      toast.error(sign_in_error[0].text)
      return
    }
    setIsLoading(true)
    try {
      if (items) {
        await manageCart.addBundleProductToCart({ sku, items, name }, count)
      } else if (!!selectedOption) {
        await manageCart.addConfigurableProductToCart(
          { sku, optionUid: selectedOption, name },
          count
        )
      } else {
        await manageCart.addItemToCart({ sku, name }, count)
      }
    } catch (err) {
      console.log(err)
    }
    setIsLoading(false)
  }

  const buildConfigOptions = () => {
    const optionsDataArr = configurable_options?.[0].values

    let optionsDataWithImages = {}
    optionsDataArr.forEach((option, i) => {
      const imageData = configurable_product_options_selection.media_gallery[i]
      let optionWithImage = { ...option, image: imageData }
      optionsDataWithImages[option.uid] = optionWithImage
    })
    return optionsDataWithImages
  }

  const sortedImages = media_gallery?.sort((a, b) => a.position - b.position)

  const isOutOfStock = stock_status === 'OUT_OF_STOCK'

  const renderActiveTabContent = () => {
    switch (activeTab) {
      case details[0].text:
        return (
          <TabItemContent>
            {description?.html ? (
              <DescContainer
                dangerouslySetInnerHTML={{ __html: description.html }}
              />
            ) : (
              <Container padding="0 0 1em 0">
                <h1>{coming_soon[0].text}</h1>
              </Container>
            )}
          </TabItemContent>
        )
      case ingredients[0].text:
        return (
          <TabItemContent>
            {ingredient ? (
              <DescContainer dangerouslySetInnerHTML={{ __html: ingredient }} />
            ) : (
              <Container padding="0 0 1em 0">
                <h1>{coming_soon[0].text}</h1>
              </Container>
            )}
          </TabItemContent>
        )
      case suggestedUseText[0].text:
        return (
          <TabItemContent>
            {suggested_use ? (
              <DescContainer
                dangerouslySetInnerHTML={{ __html: suggested_use }}
              />
            ) : (
              <Container padding="0 0 1em 0">
                <h1>{coming_soon[0].text}</h1>
              </Container>
            )}
          </TabItemContent>
        )

      default:
        break
    }
  }

  return (
    <Wrapper>
      {!!configurable_options ? (
        <ConfigProduct
          name={name}
          image={image}
          configurableOptions={buildConfigOptions()}
          setSelected={setSelectedOption}
          regularPrice={regularPrice}
          finalPrice={finalPrice}
          pv={pv}
        />
      ) : (
        <>
          <PageHeader>&nbsp;</PageHeader>
          <Container justify="center" align="center" maxWidth="800px">
            <Container justify="flex-end" align="center">
              <ProductImages mediaGallery={sortedImages} image={image} />
            </Container>
            <Container column align="right">
              <div style={{ marginBottom: '10px' }}>
                <StyledSpan data-qa={`title-${sku}`} isTitle>{name}</StyledSpan>
              </div>
              <div style={{ marginBottom: '10px' }}>
                {(isAmbassador || isPc) && regularPrice !== finalPrice && (
                  <StyledSpan data-qa={`priceLineThru-${sku}`} lineThru>{`$${
                    regularPrice?.toFixed(2) ?? ''
                  }`}</StyledSpan>
                )}
                <StyledSpan data-qa={`priceDiscount-${sku}`}>{`$${finalPrice?.toFixed(2) ?? ''}`}</StyledSpan>
              </div>
              {isAmbassador && (
                <div>
                  <StyledSpan data-qa={`pv-${sku}`} pv>{`PV ${pv}`}</StyledSpan>
                </div>
              )}
              {/* <Container align="center" padding="1em 0">
            <Rating
              rating={averageReview}
              maxRating={5}
              size="large"
              icon="star"
            />
            <span
              style={{ color: 'blue', marginLeft: '0.5em' }}
            >{`(${review_count})`}</span>
          </Container>
          {related_products?.map(product => (
            <RelatedProduct key={product.url_key} {...product} />
          ))} */}
            </Container>
          </Container>
        </>
      )}
      <Container column align="center" justify="center" margin="1em">
        <StyledSpan data-qa={`count-${count}`} count>{count}</StyledSpan>
        <Container maxWidth="500px">
          <MinusButton data-qa={`minusButton-${sku}`} onClick={() => handleSetCount('minus')}>
            <span style={{ fontSize: '2em' }}>-</span>
          </MinusButton>
          <PlusButton data-qa={`plusButton-${sku}`} onClick={() => handleSetCount('plus')}>
            <span style={{ fontSize: '2em' }}>+</span>
          </PlusButton>
        </Container>
      </Container>
      <AddButton
        content={isOutOfStock ? out_of_stock[0].text : add[0].text}
        onClick={handleAddToCart}
        loading={isLoading}
        disabled={
          isLoading ||
          (!!configurable_options && !selectedOption) ||
          isOutOfStock
        }
        data-qa={`add-${sku}`}
      />
      <Container column align="center" justify="center">
        <TabWrapper>
          {tabList.map((tabItem, index) => (
            <div key={index}>
              <TabItem
                className={tabItem === activeTab ? 'active' : ''}
                onClick={() => setActiveTab(tabItem)}
                data-qa={`${tabItem}-${sku}`}
              >
                {tabItem}
              </TabItem>

              {index !== tabList.length - 1 && <TabItemSplit />}
            </div>
          ))}
        </TabWrapper>
        <TabItemContent>{renderActiveTabContent()}</TabItemContent>
      </Container>
      {related_products?.length > 0 && (
        <Container
          column
          align="center"
          style={{ borderTop: '1px solid black', paddingTop: '1em' }}
        >
          <h2>{you_may_also_like[0].text}</h2>
          <CardsContainer>
            {related_products?.map(product => (
              <ProductCard key={product.url_key} {...product} />
            ))}
          </CardsContainer>
        </Container>
      )}
    </Wrapper>
  )
}

export default ProductSummary
