import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination, Autoplay } from 'swiper'
import { Image } from 'semantic-ui-react'

import 'swiper/css'
import 'swiper/css/pagination'
import './styles.css'

// install Swiper modules
SwiperCore.use([Pagination, Autoplay])

const HeroSwiper = ({slide_images}) => {
  const slides = slide_images.map((slide) => ({
    url: slide.image.url
  }))

  const renderHeros = slides =>
    slides.map((slide, i) => (
      <SwiperSlide key={`${slide.title}-${i}`}>
        <Image src={slide.url} fluid />
      </SwiperSlide>
    ))

  return (
    <>
      <Swiper
        autoplay={{
          delay: 4000,
        }}
        loop={true}
        loopFillGroupWithBlank={true}
        pagination={true}
        slidesPerView={1}
        className="hero-swiper"
      >
        {renderHeros(slides)}
      </Swiper>
    </>
  )
}

export default HeroSwiper
