import React, { useState, useEffect } from 'react'
import { Divider, Menu } from 'semantic-ui-react'
import { useLocation } from '@reach/router'
// Components
import { SocialLinks } from '../'
import { useAuthContext } from '../../context/AuthContext'
import { usePrismic } from '../../context/PrismicContext'
// Styles
import { StyledLink, Container, Title, Wrapper } from './Menu.styles'
// Data

const SidebarMenu = ({ toggle }) => {
  const [isActive, setIsActive] = useState('')
  const { firebaseUser, handleUserLogout } = useAuthContext()

  const {
    prismicData: { prismicSideMenu },
  } = usePrismic()

  const {
    about,
    cart,
    contact,
    hello,
    home,
    order_history,
    profile,
    programs,
    science,
    shop,
    sign_in,
    sign_out,
    user,
  } = prismicSideMenu

  const location = useLocation()
  useEffect(() => {
    if (!!location) {
      const path = location.pathname.split('/')[1]
      setIsActive(path)
    }
  }, [location])

  const handleClick = (e, { name }) => {
    setIsActive(name)
    toggle()
  }

  const handleLogout = () => {
    handleUserLogout()
    toggle()
  }

  return (
    <Wrapper>
      {firebaseUser ? (
        <Menu.Menu>
          <Menu.Item
            disabled
            content={
              <Container data-qa="greeting" large>
                <Title>{`${hello[0].text}, ${
                  firebaseUser?.displayName || user[0].text
                }`}</Title>
              </Container>
            }
          />
        </Menu.Menu>
      ) : (
        <Menu.Menu>
          <Menu.Item
            as={StyledLink}
            to="/login"
            name="login"
            content={
              <Container data-qa="menuSignIn" large>
                <Title>{`${sign_in[0].text}`}</Title>
              </Container>
            }
            onClick={handleClick}
            active={isActive === 'login'}
          />
        </Menu.Menu>
      )}
      <Divider fitted />
      <Menu.Menu style={{ marginTop: '20px' }}>
        <Menu.Item
          as={StyledLink}
          to="/"
          name=""
          content={
            <Container data-qa="menuHome">
              <Title>{`${home[0].text}`}</Title>
            </Container>
          }
          onClick={handleClick}
          active={isActive === ''}
        />
        {!!firebaseUser && (
          <Menu.Item
            as={StyledLink}
            to="/profile"
            name="profile"
            content={
              <Container data-qa="menuProfile">
                <Title>{`${profile[0].text}`}</Title>
              </Container>
            }
            onClick={handleClick}
            active={isActive === 'profile'}
          />
        )}
        <Menu.Item
          as={StyledLink}
          to="/cart"
          name="cart"
          content={
            <Container data-qa="menuCart">
              <Title>{`${cart[0].text}`}</Title>
            </Container>
          }
          onClick={handleClick}
          active={isActive === 'cart'}
        />
        {!!firebaseUser && (
          <Menu.Item
            as={StyledLink}
            to="/order-history"
            name="order-history"
            onClick={handleClick}
            active={isActive === 'order-history'}
            content={
              <Container data-qa="menuHistory">
                <Title>{`${order_history[0].text}`}</Title>
              </Container>
            }
          />
        )}
        <Menu.Item
          as={StyledLink}
          to="/products"
          name="products"
          content={
            <Container data-qa="menuShopAll">
              <Title>{`${shop[0].text}`}</Title>
            </Container>
          }
          onClick={handleClick}
          active={isActive === 'products'}
        />
        <Menu.Item
          as={StyledLink}
          to="/contact-us"
          name="contact-us"
          content={
            <Container data-qa="menuContact">
              <Title>{`${contact[0].text}`}</Title>
            </Container>
          }
          onClick={handleClick}
          active={isActive === 'contact-us'}
        />
        <Divider />
        <Menu.Item
          as={StyledLink}
          to="/about"
          name="about"
          content={
            <Container data-qa="menuAbout">
              <Title>{`${about[0].text}`}</Title>
            </Container>
          }
          onClick={handleClick}
          active={isActive === 'about'}
        />
        <Menu.Item
          as={StyledLink}
          to="/q-program"
          name="q-program"
          content={
            <Container data-qa="menuPrograms">
              <Title>{`${programs[0].text}`}</Title>
            </Container>
          }
          onClick={handleClick}
          active={isActive === 'q-program'}
        />
        <Menu.Item
          as={StyledLink}
          to="/science"
          name="science"
          content={
            <Container data-qa="menuScience">
              <Title>{`${science[0].text}`}</Title>
            </Container>
          }
          onClick={handleClick}
          active={isActive === 'science'}
        />
        {!!firebaseUser && (
          <>
            <Divider />
            <Menu.Item
              style={{ marginTop: '3em' }}
              as={StyledLink}
              to="/"
              name="logout"
              onClick={handleLogout}
              content={
                <Container data-qa="menuSignOut">
                  <Title>{`${sign_out[0].text}`}</Title>
                </Container>
              }
            />
          </>
        )}
      </Menu.Menu>
      <SocialLinks />
    </Wrapper>
  )
}

export default SidebarMenu
