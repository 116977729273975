import React from 'react'
import { Link } from 'gatsby'
import { Image } from 'semantic-ui-react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination, Navigation } from 'swiper'
// Images
import body from '../../images/category/whole-body.png'
import stress from '../../images/category/stress.png'
import energy from '../../images/category/energy-focus.png'
import fitness from '../../images/category/fitness-weight-loss.png'
import gut from '../../images/category/gut-health-detox.png'
import sleep from '../../images/category/sleep.png'
import immunity from '../../images/category/immunity.png'
import comfort from '../../images/category/comfort-relief.png'
import hair from '../../images/category/hair-skin-nails.png'

import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

import './styles.css'

const benefitsArray = [
  {
    url: 'whole-body',
    name: 'Whole Body',
    img: body,
  },
  {
    url: 'stress',
    name: 'Stress',
    img: stress,
  },
  {
    url: 'energy-focus',
    name: 'Energy & Focus',
    img: energy,
  },
  {
    url: 'fitness-weight-loss',
    name: 'Fitness & Weight Loss',
    img: fitness,
  },
  {
    url: 'gut-health-detox',
    name: 'Gut Health & Detox',
    img: gut,
  },
  {
    url: 'sleep',
    name: 'Sleep',
    img: sleep,
  },
  {
    url: 'immunity',
    name: 'Immunity',
    img: immunity,
  },
  {
    url: 'comfort-relief',
    name: 'Comfort & Relief',
    img: comfort,
  },
  {
    url: 'hair-skin-nails',
    name: 'Hair, Skin & Nails',
    img: hair,
  },
]

SwiperCore.use([Pagination, Navigation])

const BenefitsSwiper = () => {
  const renderBenefits = benefits =>
    benefits.map(benefit => (
      <SwiperSlide data-qa={`benefit-${benefit.name}`} key={`${benefit.url}`}>
        <Image
          as={Link}
          to={`products/benefit/${benefit.url}`}
          src={benefit.img}
          size="tiny"
        />
        <p>{benefit.name}</p>
      </SwiperSlide>
    ))

  return (
    <>
      <Swiper
        breakpoints={{
          480: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
          640: {
            slidesPerView: 6,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 8,
            spaceBetween: 20,
          },
        }}
        loop={true}
        loopFillGroupWithBlank={true}
        pagination={{
          clickable: true,
        }}
        slidesPerView={4}
        spaceBetween={20}
        className="benefit-swiper"
        data-qa="benefitSwiper"
      >
        {renderBenefits(benefitsArray)}
      </Swiper>
    </>
  )
}

export default BenefitsSwiper
