import React from 'react'
import PropTypes from 'prop-types'

import DefaultLayout from './Default'
import {setAnalyticsStream} from '../utils/analytics'



const Layout = ({ children, pageContext }) => {
  setAnalyticsStream()
  switch (pageContext.layout){
    case "space-around-content":
      return <DefaultLayout>{children}</DefaultLayout>
    default:
      return <DefaultLayout isFullWidth>{children}</DefaultLayout>
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  pageContext: PropTypes.object.isRequired,
}

export default Layout
