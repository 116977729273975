import React, { useState } from 'react'
import { Link, navigate } from 'gatsby'
import toast from 'react-hot-toast'
import { Rating, Image, Icon } from 'semantic-ui-react'
// Styles
import {
  AddButton,
  Wrapper,
  CardItem,
  CardDesc,
  CardTitle,
  CardMeta,
  CardContent,
  RatingContainer,
  RatingContent,
  StyledSpan,
} from './ProductCard.styles'
// Context
import { useAuthContext } from '../../context/AuthContext'
import { usePrismic } from '../../context/PrismicContext'
import { useCart } from '../../context/CartContext'

const ProductRating = ({ stars = 0, summary, nickname }) => (
  <RatingContainer>
    <Icon name="user circle outline" size="big" />
    <RatingContent>
      <div>
        <Rating disabled rating={stars} maxRating={5} size="large" />
      </div>
      <div>{summary}</div>
      <div>{`- ${nickname}`}</div>
    </RatingContent>
  </RatingContainer>
)

const ProductCard = product => {
  const {
    prismicData: { prismicProductsPage },
  } = usePrismic()
  const {
    sign_in_error,
    add,
    default_rating_summary,
    default_rating_nick_name,
    out_of_stock,
  } = prismicProductsPage

  const {
    name,
    short_description,
    price_range,
    image,
    media_gallery,
    reviews,
    url_key,
    pv,
    items,
    trending = false,
    stock_status,
    sku,
  } = product

  // Conditionally set vars if product has existing reviews
  let nickname, summary, average_rating
  if (reviews?.items[0]) {
    ;({ nickname, summary, average_rating } = reviews.items[0])
  }

  const { isUserAuthenticated, isAmbassador, isPc } = useAuthContext()
  const { manageCart } = useCart()

  const finalPrice = price_range?.maximum_price?.final_price?.value
  const regularPrice = price_range?.maximum_price?.regular_price?.value

  const [isLoading, setIsLoading] = useState(false)

  const handleAddToCart = async () => {
    if (!isUserAuthenticated) {
      toast.error(sign_in_error[0].text)
      return
    }

    if (product.configurable_options) {
      navigate(`/products/${url_key}`)
      return
    }

    setIsLoading(true)
    try {
      items
        ? await manageCart.addBundleProductToCart(product)
        : await manageCart.addItemToCart(product)
    } catch (err) {
      console.log(err)
    }
    setIsLoading(false)
  }

  const sortedImages = media_gallery?.sort((a, b) => a.position - b.position)

  const productImage = sortedImages[0]?.url ?? image.url

  const isOutOfStock = stock_status === 'OUT_OF_STOCK'

  return (
    <Wrapper trending={trending}>
      <CardItem>
        <Image data-qa={`productImage-${sku}`} as={Link} to={`/products/${url_key}`} src={productImage} />
        <AddButton
          loading={isLoading}
          disabled={isLoading || isOutOfStock}
          content={isOutOfStock ? out_of_stock[0].text : add[0].text}
          onClick={handleAddToCart}
          data-qa={`productAdd-${sku}`}
        />
        <CardContent>
          <CardTitle data-qa={`productName-${sku}`}>{name}</CardTitle>
          <CardDesc data-qa={`productDesc-${sku}`}>
            {short_description?.html && (
              <div
                dangerouslySetInnerHTML={{ __html: short_description.html }}
              />
            )}
          </CardDesc>
          <CardMeta>
            <div>
              {(isAmbassador || isPc) && regularPrice !== finalPrice && (
                <StyledSpan data-qa={`productPriceRegular-${sku}`} lineThru>
                  {regularPrice ? `$${regularPrice.toFixed(2)}` : null}
                </StyledSpan>
              )}
              <StyledSpan data-qa={`productPriceDiscount-${sku}`}>{`$${finalPrice.toFixed(2)}`}</StyledSpan>
            </div>
            {isAmbassador && <StyledSpan data-qa={`productPV-${sku}`} pv>{`PV ${pv}`}</StyledSpan>}
          </CardMeta>
        </CardContent>
        {trending && (
          <CardContent>
            <ProductRating
              stars={average_rating / 20}
              summary={summary || default_rating_summary[0].text}
              nickname={nickname || default_rating_nick_name[0].text}
            />
          </CardContent>
        )}
      </CardItem>
    </Wrapper>
  )
}

export default ProductCard
