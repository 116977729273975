import styled, { css } from 'styled-components'
import { Card, Button } from 'semantic-ui-react'

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: ${({ theme }) => theme.colors.white};

  ${props => !props.trending && 'padding-bottom: 1rem'};
`

export const CardItem = styled(Card)`
  &&& {
    display: flex;
    flex-flow: column nowrap;
    width: 90%;
    box-shadow: none;
  }
`

export const CardTitle = styled.span`
  font-size: 1.2em;
  font-weight: bolder;
`

export const CardContent = styled(Card.Content)`
  &&& {
    display: flex;
    flex-flow: column nowrap;
    border-top: none !important;
    padding: 0 0 0 5px;
    text-align: center;
  }
`

export const CardMeta = styled(Card.Meta)`
  &&& {
    display: flex;
    flex-flow: column nowrap;
    align-self: flex-end;
    align-items: center;
    flex: 1;
    width: 100%;
  }
`

export const CardDesc = styled.div`
  margin: 10px 0 15px 0;
`

export const RatingContainer = styled.div`
  display: flex;
  line-height: 1rem;
  align-items: center;
  margin-top: 15px;
`

export const RatingContent = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-flow: column nowrap;
  padding-left: 10px;
  width: 100%;
`

export const AddButton = styled(Button)`
  &&& {
    background-color: ${props => props.theme.colors.metallicSeaweed};
    color: ${props => props.theme.colors.white};
    font-weight: bold;
    padding: 7px 0;
    margin-bottom: 1em;
    width: 80% !important;
    border-radius: 20px;
    align-self: center;
    box-shadow: 4px 5px 10px gray;
    text-transform: uppercase;

    :active {
      box-shadow: none;
      background-color: ${props => props.theme.colors.metallicSeaweed};
      color: ${props => props.theme.colors.white};
    }

    @media ${({ theme }) => theme.device.sm} {
      :hover {
        box-shadow: none;
        background-color: ${props => props.theme.colors.metallicSeaweed};
        color: ${props => props.theme.colors.white};
      }
      :focus {
        background-color: ${props => props.theme.colors.metallicSeaweed};
        color: ${props => props.theme.colors.white};
      }
    }
  }
`

export const StyledSpan = styled.span`
  font-size: 1.3em;

  ${props =>
    props.lineThru &&
    css`
      font-size: 1.1em;
      opacity: 0.6;
      margin-right: 5px;
      text-decoration: line-through;
    `}

  ${props =>
    props.pv &&
    css`
      width: 100%;
      text-align: center;
      font-size: 1em;
      font-weight: bold;
    `}
`
