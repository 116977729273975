import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

//styles
import { ErrorMsg } from '../../styles/LoginPage.styles'
import {
  StyledInput,
  StyledForm,
  RedeemButton,
} from './AddCoupon.styles'

import { addCouponCode } from '../../utils/validations'
import { usePrismic } from '../../context/PrismicContext'
import toast from 'react-hot-toast'
import { useCart } from '../../context/CartContext'

const AddCoupon = () => {
  const [isLoading, setIsLoading] = useState(false)
  const {
    prismicData: { prismicCartPage },
  } = usePrismic()
  const { manageCart, handleUpdateCartData } = useCart()

  const { coupon_placeholder, redeem } = prismicCartPage

  const onSubmit = async data => {
    setIsLoading(true)
    await manageCart
      .addCouponToCart(data.code)
      .then(() => {
        toast.success('Coupon Added!')
        setIsLoading(false)
        reset()
      })
      .then(handleUpdateCartData)
      .catch(err => {
        toast.error(err.response.errors[0].message)
        setIsLoading(false)
        reset()
      })
  }

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(addCouponCode),
  })

  return (
    <StyledForm row onSubmit={handleSubmit(onSubmit)}>
      <StyledInput 
        {...register('code')}
        placeholder={coupon_placeholder[0].text}
        data-qa="inputCoupon"
      />
      <ErrorMsg>{errors.code?.message}</ErrorMsg>
      <RedeemButton data-qa="redeemButton" loading={isLoading} type="submit">
        {redeem[0].text}
      </RedeemButton>
    </StyledForm>
  )
}

export default AddCoupon
