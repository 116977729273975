import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types'
import { Link, navigate } from 'gatsby'
import toast from 'react-hot-toast'
// Components
import ShoppingCartIcon from './ShoppingCartIcon'
import SearchMenu from './SearchMenu'
// Context
import { useStore } from '../../context/StoreContext'
import { useAuthContext } from '../../context/AuthContext'
import { useCart } from '../../context/CartContext'
// Styles
import {
  Wrapper,
  IconContainer,
  IconWrapper,
  Icon,
  CenteredIcon,
} from './Header.styles'
// Images/Icons
import bars from '../../images/main/Bars.png'
import search from '../../images/main/Search.png'
import cart from '../../images/main/Cart.png'
import logo from '../../images/white-q.svg'
import { Button } from 'semantic-ui-react'

const Header = ({ showSidebar }) => {
  const [showSearch, setShowSearch] = useState(false)
  // const [showStoreLanguageModal, setShowStoreLanguageModal] = useState(false)
  const { isUserAuthenticated, isEventSite } = useAuthContext()
  const { storeData, isLoadingStore } = useStore()
  const { cartData } = useCart()

  const allProducts = storeData['all-products']?.products ?? []
  const cartCount = cartData?.items?.reduce(
    (agg, { quantity }) => agg + (quantity || 0),
    0
  )

  const handleShowSearch = () => {
    if (isLoadingStore) return
    setShowSearch(!showSearch)
  }

  const handleCartClick = () => {
    if (!isUserAuthenticated) {
      toast.error('Sign in to view your cart')
      navigate('/login')
    } else navigate('/cart')
  }

  return (
    <>
      <Wrapper>
        <IconWrapper data-qa="menuButton" onClick={() => showSidebar()}>
          <Icon src={bars} />
        </IconWrapper>
        <CenteredIcon data-qa="qIcon" as={Link} to="/">
          <IconWrapper>
            <Icon src={logo} />
          </IconWrapper>
        </CenteredIcon>
        <IconContainer>
          <IconWrapper data-qa="search" onClick={handleShowSearch}>
            <Icon src={search} disabled={isLoadingStore} />
          </IconWrapper>
          {/* {!isEventSite && (
              <IconWrapper onClick={() => setShowStoreLanguageModal(true)}>
              <Icon src={globe} />
              </IconWrapper>
            )} */}
          <IconWrapper data-qa="cart" onClick={handleCartClick}>
            <ShoppingCartIcon cartCount={cartCount}>
              <Icon src={cart} />
            </ShoppingCartIcon>
          </IconWrapper>
        </IconContainer>
        {/* {!isEventSite && (
          <StoreLanguageModal
            open={showStoreLanguageModal}
            setShowStoreLanguageModal={setShowStoreLanguageModal}
          />
        )} */}
      </Wrapper>

      {showSearch && (
        <Wrapper justify="center">
          <SearchMenu products={allProducts} callback={handleShowSearch} />
        </Wrapper>
      )}
    </>
  )
}

Header.propTypes = {
  showSidebar: PropTypes.func,
}

export default Header
