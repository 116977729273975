import React from 'react'
// Components
import qLogo from '../../images/white-q.svg'
// Context
import ReorderButton from '../Buttons/ReorderButton'
// Styles
import {
  Wrapper,
  Main,
  Container,
  StyledSpan,
  QLogo,
  StyledLink,
} from './OrderFailed.styles'

type OrderFailedProps = {
  orderNumber: string
  orderStatus: string
  userName: string
  show: React.Dispatch<React.SetStateAction<boolean>>
  errorData: {
    error?: number
    message?: string
    gatewayResponse?: {
      status?: string
    }
  }
}

// TODO: Add OrderFailed text to Prismic
const OrderFailed = ({
  orderNumber,
  orderStatus,
  userName,
  show,
  errorData,
}: OrderFailedProps) => {
  return (
    <Wrapper>
      <Main>
        <QLogo src={qLogo} />
        <StyledSpan bold>
          Order
          <StyledLink to="/order-history">{` ${orderNumber} `}</StyledLink>
          Failed
        </StyledSpan>
        <StyledSpan bold>{`Status: ${
          errorData?.gatewayResponse?.status ?? orderStatus
        }`}</StyledSpan>
        <Container>
          <StyledSpan>
            {errorData?.message ??
              `${userName}, an error occurred while processing your payment. Please check
    your card details and try again.`}
          </StyledSpan>
        </Container>
        <Container>
          <StyledSpan emphasized>
            If you have any questions, please contact
            <a
              href={`mailto:support@qsciences.com?subject=Re:%20Order%20${orderNumber}`}
            >
              {' '}
              customer support
            </a>
          </StyledSpan>
        </Container>
        <ReorderButton content="Rebuild Cart" orderNumber={orderNumber} />
      </Main>
    </Wrapper>
  )
}

export default OrderFailed
