import { Integrations } from '@sentry/tracing'

const integrations = new Integrations.BrowserTracing()
export const localSentry = {
  dsn: '',
  integrations: [integrations],
  tracesSampleRate: 0.0,
  environment: 'local',
}

export const developmentSentry = {
  dsn: 'https://babd160fdade4196984407cac39edf28@o924845.ingest.sentry.io/5904534',
  integrations: [integrations],
  tracesSampleRate: 0.0,
  environment: 'development',
}

export const stagingSentry = {
  dsn: 'https://babd060fdade4196984407cac39edf28@o924845.ingest.sentry.io/5904534',
  integrations: [integrations],
  tracesSampleRate: 0.0,
  environment: 'staging',
}

export const productionSentry = {
  dsn: 'https://babd160fdade4196984407cac39edf28@o924845.ingest.sentry.io/5904534',
  integrations: [integrations],
  tracesSampleRate: 0.1,
  environment: 'production',
}
