import { GraphQLClient } from 'graphql-request'
import {
  CONFIRM_ACCESS_CODE,
  DIRECT_SCALE_INFO,
  GET_CARDS_ON_FILE,
  GET_ONE_TIME_NEXIO_TOKEN,
  LOGIN_USER,
  LOGIN_VALIDATION_PROCESS,
  NEXIO_POST_TRANSACTION,
  SAVE_CARD_TO_ASSOCIATE,
} from './graphql/qservices/mutations'
import { GET_PROFILE, GET_USER_TREE_NODE } from './graphql/qservices/queries'
import {
  CreditCard,
  CreditCardsForAssociateResponse,
  NexioPostTransactionResponse,
  NexioPostTransData,
  OneTimeTokenResponse,
} from '../types/PaymentsTypes'
import environment from '../utils/environment'

const endpoint = environment.QSERVICES_API_URL
const badEndpoint = 'https://qservices-dev.qsciences.com/bad/graphql'

const client = new GraphQLClient(endpoint)
const badClient = new GraphQLClient(badEndpoint)

export const QService = {
  setToken: token => client.setHeader('authorization', `Bearer ${token}`),
  login: async () =>
    await client.request(LOGIN_USER, { ambassadorOnly: false }),
  getDirectScaleUser: async userName =>
    await client.request(DIRECT_SCALE_INFO, {
      ambassadorOnly: false,
      userName,
    }),
  getUserTreeNode: async legacyId =>
    await client.request(GET_USER_TREE_NODE, legacyId),
  getUserProfile: async associateId =>
    await client.request(GET_PROFILE, associateId),
  loginValidationProcess: async (method, loginName, verificationInfo) =>
    await client.request(LOGIN_VALIDATION_PROCESS, {
      method,
      loginName,
      verificationInfo,
    }),
  confirmAccessCode: async (loginName, accessCode) =>
    await client.request(CONFIRM_ACCESS_CODE, { loginName, accessCode }),

  getNexioOneTimeCode: async (): Promise<string> => {
    const {
      nexioOneTimeTokenIframe: { token },
    } = await client.request<OneTimeTokenResponse>(GET_ONE_TIME_NEXIO_TOKEN)
    return token
  },
  getCardsOnFile: async associateId => {
    const { nexioCreditCardsForAssociate } =
      await client.request<CreditCardsForAssociateResponse>(GET_CARDS_ON_FILE, {
        associateId,
      })
    return nexioCreditCardsForAssociate
  },
  saveCardData: async (associateId, cardData: CreditCard) =>
    await client.request(SAVE_CARD_TO_ASSOCIATE, { associateId, cardData }),
  nexioPostTransaction: async (data: NexioPostTransData) => {
    const { nexioPostTransaction } =
      await client.request<NexioPostTransactionResponse>(
        NEXIO_POST_TRANSACTION,
        data
      )
    return nexioPostTransaction
  },
  // nexioPostTransaction: async (data: NexioPostTransData) => {
  //   const { nexioPostTransaction } =
  //     await badClient.request<NexioPostTransactionResponse>(
  //       NEXIO_POST_TRANSACTION,
  //       data
  //     )
  //   return nexioPostTransaction
  // },
}
