import React from 'react'
// Context & Utils
import { OrderDetailValues } from '../../utils/cartHelpers'
import { usePrismic } from '../../context/PrismicContext'

import {
  Wrapper,
  Container,
  SavedWrapper,
  DiscountText,
} from './OrderDetails.styles'

const OrderDetails = ({
  subtotal_excluding_tax,
  subtotal_including_tax,
  grand_total,
  shipping,
  item_count,
  total_savings,
  discounts,
}) => {
  const {
    prismicData: { prismicCartPage },
  } = usePrismic()

  const {
    tax,
    shipping: shippingPrismicText,
    sub_total,
    total,
    items,
    you_save_on_your_order,
  } = prismicCartPage

  const { getAmounts, getTotalWithShipping } = OrderDetailValues({
    subtotal_excluding_tax,
    subtotal_including_tax,
    grand_total,
    shipping,
  })
  const { subTotalText, taxesText, shippingText } = getAmounts()

  const getSavingText = () => {
    const initialText = you_save_on_your_order[0].text
    const replacedHTML = initialText.replace(
      '{savedAmount}',
      `<span>$${total_savings.toFixed(2)}</span>`
    )

    return <SavedWrapper data-qa="saved" dangerouslySetInnerHTML={{ __html: replacedHTML }} />
  }

  const showDiscount = () => {
    if (!discounts) return null

    return discounts.map((discount, i) => {
      const discountLabel = discount?.label ?? 'Discount'
      const discountValue = `-$${discount?.amount?.value?.toFixed(2) ?? ''}`
      return (
        <Container key={`${discount.label}-${i}`}>
          <DiscountText>{discountLabel}</DiscountText>
          <DiscountText>{discountValue}</DiscountText>
        </Container>
      )
    })
  }

  return (
    <Wrapper>
      <Container>
        <span data-qa="subTotalText">{`${sub_total[0].text} (${item_count} ${items[0].text})`}</span>
        <span data-qa="subTotal">${subTotalText}</span>
      </Container>
      {showDiscount()}
      <Container >
        <span data-qa="taxText">{tax[0].text}</span>
        <span data-qa="tax">${taxesText}</span>
      </Container>
      <Container>
        <span data-qa="shippingText">{shippingPrismicText[0].text}</span>
        <span data-qa="shipping">{`$${shippingText}`}</span>
      </Container>
      <Container total>
        <span data-qa="totalText">{total[0].text}</span>
        <span data-qa="total">${getTotalWithShipping()}</span>
      </Container>
      {total_savings > 0 && getSavingText()}
    </Wrapper>
  )
}

export default OrderDetails
