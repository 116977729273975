export const eventCategoryProducts = () => ({
  type: {
    subCategories: {
      kids: { products: [] },
      'oral-sprays': { products: [] },
      nutritionals: { products: [] },
      hemp: { products: [] },
      topicals: { products: [] },
      'drink-mixes': { products: [] },
      collagen: { products: [] },
      protein: { products: [] },
      packs: { products: [] },
      terpenes: { products: [] },
    },
    products: [],
  },
  benefit: {
    subCategories: {},
    products: [],
  },
})

export const categoryProducts = () => ({
  type: {
    subCategories: {
      kids: { products: [] },
      'oral-sprays': { products: [] },
      nutritionals: { products: [] },
      hemp: { products: [] },
      topicals: { products: [] },
      'drink-mixes': { products: [] },
      collagen: { products: [] },
      protein: { products: [] },
      packs: { products: [] },
      terpenes: { products: [] },
      swag: { products: [] },
      promotions: { products: [] },
    },
    products: [],
  },
  benefit: {
    subCategories: {},
    products: [],
  },
})
