import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Header, Modal } from 'semantic-ui-react'
// Components
import { useAuthContext } from '../../context/AuthContext'
import { Primary as SendButton, Warning as CancelButton } from '..'
// Styles
import {
  StyledForm,
  StyledInput,
  StyledLink,
  SuccessMsg,
  ErrorMsg,
  Container,
} from './ForgotPassword.styles'
// Utils
import { forgotPasswordSchema } from '../../utils/validations'
// Hooks
import useShowError from '../../hooks/useShowError'

const defaultState = {
  open: false,
  success: false,
  loading: false,
}

const ForgotPasswordModal = ({ resetLogin }) => {
  const [{ open, success, loading }, setState] = useState(defaultState)

  const { resetPassword } = useAuthContext()
  const [error, setError, showError] = useShowError()

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(forgotPasswordSchema) })

  const onSubmit = data => {
    if (data.resetEmail === '') return
    setState(prevState => ({ ...prevState, loading: true }))
    resetPassword(data.resetEmail)
      .then(() => {
        setState(prevState => ({ ...prevState, loading: false, success: true }))
      })
      .catch(err => {
        setState(prevState => ({
          ...prevState,
          loading: false,
          success: false,
        }))
        setError(err.message)
      })
  }

  const handleClose = () => {
    reset()
    resetLogin()
    setState(defaultState)
  }

  return (
    <Modal
      open={open}
      centered={false}
      closeOnDimmerClick={false}
      closeOnEscape
      onClose={handleClose}
      onOpen={() => setState(prevState => ({ ...prevState, open: true }))}
      trigger={<StyledLink data-qa="signInResetPassword">Reset Password</StyledLink>}
    >
      <Header style={{ margin: '1em' }} textAlign="center" size="huge">
        Forgot Your Password?
      </Header>
      <Modal.Content>
        <Header textAlign="center" color="grey">
          Please enter your email address below.
        </Header>
        {error && showError()}
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <Container>
            <ErrorMsg>{errors.resetEmail?.message}</ErrorMsg>
            <StyledInput
              {...register('resetEmail')}
              placeholder="Email Address"
              data-qa="emailInput"
            />
          </Container>
          {success && !loading ? (
            <SuccessMsg>Email sent!</SuccessMsg>
          ) : (
            <SendButton type="submit" loading={loading}>
              RESET PASSWORD
            </SendButton>
          )}
          <CancelButton onClick={handleClose}>
            {success ? 'CLOSE' : 'CANCEL'}
          </CancelButton>
        </StyledForm>
      </Modal.Content>
    </Modal>
  )
}

export default ForgotPasswordModal
