import React, { useState, useRef } from 'react'
import { Popup } from 'semantic-ui-react'
// Components
import { OrderCost, OrderItem } from '.'
// Utils & Services
import { DateUtils } from '../../utils/dateUtils'
// Images
import isOnHold from '../../images/order/statusIcons/isOnHold.svg'
import isCanceled from '../../images/order/statusIcons/isCanceled.svg'
import isClosed from '../../images/order/statusIcons/isClosed.svg'
import isComplete from '../../images/order/statusIcons/isComplete.svg'
import isOpenOrder from '../../images/order/statusIcons/isOpenOrder.svg'
import isPending from '../../images/order/statusIcons/isPending.svg'
import isPendingPayment from '../../images/order/statusIcons/isPendingPayment.svg'
import isPendingReview from '../../images/order/statusIcons/isPendingReview.svg'
import isProcessing from '../../images/order/statusIcons/isProcessing.svg'
import isReadyForPickup from '../../images/order/statusIcons/isReadyForPickup.svg'
// Styles
import {
  OrderItemViewContainer,
  OrderHeader,
  HeaderLeftContent,
  HeaderRightContent,
  StyledSpan,
  StatusIcon,
} from './Orders.styles'

const showPopup = (content, context, trigger) => (
  <Popup
    style={{ fontWeight: 'bold' }}
    content={`Status: ${content}`}
    context={context}
    trigger={trigger}
    position="top center"
    hideOnScroll={true}
  />
)

// BUG: Need to update showPopup component to use React.forwardRef()
const OrderCard = ({ number, status, order_date, items, total }) => {
  const [pvTotal, setPvTotal] = useState(0)
  const popupRef = useRef()

  // const {
  //   prismicData: { prismicOrderHistoryPage },
  // } = usePrismic()

  // const { view_invoice } = prismicOrderHistoryPage

  const showStatusIcon = status => {
    switch (status) {
      case 'On Hold':
        return showPopup('On Hold', popupRef, <StatusIcon src={isOnHold} />)
      case 'Canceled':
        return showPopup('Canceled', popupRef, <StatusIcon src={isCanceled} />)
      case 'Closed':
        return showPopup('Closed', popupRef, <StatusIcon src={isClosed} />)
      case 'Complete':
        return showPopup('Complete', popupRef, <StatusIcon src={isComplete} />)
      case 'Open':
        return showPopup('Open', popupRef, <StatusIcon src={isOpenOrder} />)
      case 'Pending':
        return showPopup('Pending', popupRef, <StatusIcon src={isPending} />)
      case 'Pending Payment':
        return showPopup(
          'Pending Payment',
          popupRef,
          <StatusIcon src={isPendingPayment} />
        )
      case 'Pending Review':
        return showPopup(
          'Pending Review',
          popupRef,
          <StatusIcon src={isPendingReview} />
        )
      case 'Processing':
        return showPopup(
          'Processing',
          popupRef,
          <StatusIcon src={isProcessing} />
        )
      case 'Ready for Pickup':
        return showPopup(
          'Ready for Pickup',
          popupRef,
          <StatusIcon src={isReadyForPickup} />
        )

      default:
        break
    }
  }

  return (
    <OrderItemViewContainer>
      <OrderHeader>
        <HeaderLeftContent>
          <StyledSpan data-qa={`order-${number}`} padding="0 5px 0 0" big>
            {number}
          </StyledSpan>
          {/* <StyledSpan gray small>
            { view_invoice[0].text }
          </StyledSpan> */}
        </HeaderLeftContent>
        <div data-qa={`order-${number}-Status-${status}`} ref={popupRef}>{showStatusIcon(status)}</div>
        <HeaderRightContent>
          <StyledSpan data-qa={`orderDate-${order_date}`} big>{DateUtils.formatDate(order_date)}</StyledSpan>
        </HeaderRightContent>
      </OrderHeader>
      {items?.map((product, i) => (
        <OrderItem
          key={`${product.product_sku}-${i}`}
          returnPv={setPvTotal}
          orderNumber={number}
          {...product}
        />
      ))}
      <OrderCost data-qa={`orderPVTotal-${pvTotal}`} pvTotal={pvTotal} {...total} orderNumber={number} />
    </OrderItemViewContainer>
  )
}

export default OrderCard
