import React, { useState, useEffect } from 'react'
import { useAuthContext } from '../../context/AuthContext'
import { Container } from '../../styles/LoginPage.styles'
import { Logo, StyledModal } from '../Login/Login.styles'
import { Primary as SubmitButton } from '../'

const AmbassadorOnlyModal = () => {
  const [open, setOpen] = useState(false)
  const {
    isAmbassador,
    isPc,
    isRetail,
    isUserAuthenticated,
    handleUserLogout,
  } = useAuthContext()

  useEffect(() => {
    // if (isUserAuthenticated && isRetail) setOpen(true)
  }, [isRetail, isUserAuthenticated])

  const handleClose = () => {
    setOpen(false)
    handleUserLogout()
  }

  return (
    <StyledModal
      centered={false}
      onClose={handleClose}
      open={open}
      closeOnDimmerClick={true}
      dimmer="blurring"
    >
      <Container direction="column">
        <Logo />
        <Container direction="column">
          <span>Thanks for signing in to the Shop Q beta.</span>
          <span>At this time, the beta is only available to</span>
          <span>Q Sciences Ambassadors and Preferred Customers</span>
        </Container>
        <Container direction="column">
          <SubmitButton onClick={handleClose}>OK</SubmitButton>
        </Container>
      </Container>
    </StyledModal>
  )
}

export default AmbassadorOnlyModal
