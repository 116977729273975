import React from 'react'
import { Icon } from 'semantic-ui-react'

// Styles
import {
  Wrapper,
  Title,
  Container,
  StyledText,
  ListContainer,
  ListItem,
  StartSavingButton,
} from './PcOffer.styles'

type PcOfferProps = {
  addPcOfferToCart: () => void;
}

const PcOffer = ({
  addPcOfferToCart,
}: PcOfferProps) => {
  return (
    <Wrapper>
      <Title>
        Save $15.00 on your order be becoming a Preferred Customer!
      </Title>

      <Container>
        <StyledText>For a $10 annual fee you get:</StyledText>
        <ListContainer>
          <ListItem>
            <Icon name="check" size="small" />
            <StyledText>
              Up to 30% off products
            </StyledText>
          </ListItem>

          <ListItem>
            <Icon name="check" size="small" />
            <StyledText>
              Autoship your favorite products
            </StyledText>
          </ListItem>

          <ListItem>
            <Icon name="check" size="small" />
            <StyledText>
              Earn Q Reward points
            </StyledText>
          </ListItem>
        </ListContainer>
      </Container>

      <StartSavingButton onClick={addPcOfferToCart}>
        Start Saving Today
      </StartSavingButton>
    </Wrapper>
  )
}

export default PcOffer;
