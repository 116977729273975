import React from 'react'
import { Icon } from 'semantic-ui-react'
import { Link, navigate } from 'gatsby'
// Components
import ignite from '../../images/ignite.png'
import qLogo from '../../images/white-q.png'

import {
  Wrapper,
  Main,
  Container,
  StyledSpan,
  IgniteImage,
  QLogo,
  CloseButton,
  StyledLink,
  InfoIcon,
} from './OrderConfirmation.styles'

const OrderConfirmation = ({
  orderNumber = '',
  email = '',
  show,
  isEventSite,
}) => {
  const handleOnClick = () => {
    show(false)
    navigate('/')
  }
  const showEventSiteMessage = () => (
    <>
      <IgniteImage src={ignite} />
      <StyledSpan bolder>Thank you for your order!</StyledSpan>
      <StyledSpan padded>
        Your order has been received for processing.
      </StyledSpan>
      <Container padded>
        <Icon.Group>
          <InfoIcon size="huge" name="circle outline" fitted />
          <InfoIcon name="info" size="large" fitted />
        </Icon.Group>
        <Container padded>
          <StyledSpan bolder>Confirmation number:</StyledSpan>
          <StyledLink
            data-qa={`eventOrderNumber-${orderNumber}`}
            to="/order-history"
          >{`${orderNumber}`}</StyledLink>
        </Container>
        <StyledSpan
          data-qa={`eventEmail-${email}`}
          bold
        >{`We will send an email to ${email} when your order is ready for pickup at the IGNITE fulfillment center.`}</StyledSpan>
      </Container>
      <Container info>
        <StyledSpan emphasized>
          To speed up the pickup process please have your confirmation email
          ready to show one of our friendly Q staff at fulfillment.
        </StyledSpan>
      </Container>
    </>
  )
  return (
    <Wrapper>
      <Main>
        <QLogo src={qLogo} />
        <StyledSpan bolder>Thank you for your order!</StyledSpan>
        <StyledSpan padded>
          Your order has been received for processing.
        </StyledSpan>
        <Container padded>
          <StyledSpan bolder>Order number:</StyledSpan>
          <StyledLink
            data-qa={`orderNumber-${orderNumber}`}
            to="/order-history"
          >{`${orderNumber}`}</StyledLink>
        </Container>
        <Container info>
          <StyledSpan
            data-qa={`email-${email}`}
            bold
          >{`We will send a confirmation email to ${email} with the details of your order.`}</StyledSpan>
          <StyledSpan emphasized>
            Remember, you can always view the{' '}
            <Link to="/order-history">Order History</Link> page to see your past
            orders.
          </StyledSpan>
        </Container>
        <CloseButton
          data-qa="closeButton"
          style={{ width: '300px' }}
          onClick={handleOnClick}
        >
          KEEP SHOPPING
        </CloseButton>
      </Main>
    </Wrapper>
  )
}

export default OrderConfirmation
