import React from 'react'
import { useAuthContext } from '../../context/AuthContext'
import { usePrismic } from '../../context/PrismicContext'
import ReorderButton from '../Buttons/ReorderButton'

import {
  OrderCostInfo,
  OrderCostItemTitle,
  OrderCostWrapper,
  ProductCostInfo,
  StyledSpan,
} from './Orders.styles'

const OrderCost = ({
  grand_total,
  pvTotal = 0,
  subtotal,
  total_shipping,
  total_tax,
  discounts,
  orderNumber,
}) => {
  const { isAmbassador } = useAuthContext()

  const {
    prismicData: { prismicOrderHistoryPage },
  } = usePrismic()

  const {
    subtotal: subTotalText,
    tax,
    shipping,
    total,
    total_pv,
  } = prismicOrderHistoryPage

  const subTotalValue = `$${subtotal?.value?.toFixed(2) ?? ''}`
  const grandTotalValue = `$${grand_total?.value?.toFixed(2) ?? ''}`
  const taxesTotalValue = `$${total_tax?.value?.toFixed(2) ?? ''}`
  const shippingTotalValue = `$${total_shipping?.value?.toFixed(2) ?? ''}`

  const discountValue = `-$${discounts[0]?.amount?.value?.toFixed(2) ?? null}`
  const shouldShowDiscounts = discounts?.length

  return (
    <>
      <OrderCostWrapper>
        <OrderCostInfo>
          <OrderCostItemTitle data-qa={`orderSubTotalText-${orderNumber}`} bold>{subTotalText[0].text}</OrderCostItemTitle>
          <ProductCostInfo>
            <StyledSpan data-qa={`orderSubTotal-${orderNumber}`} textAlign="right" width="100%">
              {subTotalValue}
            </StyledSpan>
          </ProductCostInfo>
        </OrderCostInfo>
        {shouldShowDiscounts ? (
          <OrderCostInfo>
            <OrderCostItemTitle data-qa="orderDiscountText" bold>Discounts</OrderCostItemTitle>
            <ProductCostInfo>
              <StyledSpan data-qa={`orderDiscount-${orderNumber}`} discount textAlign="right" width="100%">
                {discountValue}
              </StyledSpan>
            </ProductCostInfo>
          </OrderCostInfo>
        ) : null}
        <OrderCostInfo>
          <OrderCostItemTitle data-qa={`orderTaxText-${orderNumber}`} bold>{tax[0].text}</OrderCostItemTitle>
          <ProductCostInfo>
            <StyledSpan data-qa={`orderTax-${orderNumber}`} textAlign="right" width="100%">
              {taxesTotalValue}
            </StyledSpan>
          </ProductCostInfo>
        </OrderCostInfo>
        <OrderCostInfo>
          <OrderCostItemTitle data-qa={`orderShippingText-${orderNumber}`} bold>{shipping[0].text}</OrderCostItemTitle>
          <ProductCostInfo>
            <StyledSpan data-qa={`orderShipping-${orderNumber}`} textAlign="right" width="100%">
              {shippingTotalValue}
            </StyledSpan>
          </ProductCostInfo>
        </OrderCostInfo>
        <OrderCostInfo>
          <OrderCostItemTitle data-qa={`orderTotalText-${orderNumber}`} bold>{total[0].text}</OrderCostItemTitle>
          <ProductCostInfo>
            <StyledSpan data-qa={`orderTotal-${orderNumber}`} textAlign="right" width="100%">
              {grandTotalValue}
            </StyledSpan>
          </ProductCostInfo>
        </OrderCostInfo>
        {isAmbassador && (
          <OrderCostInfo>
            <OrderCostItemTitle data-qa={`orderPVText-${orderNumber}`} bold>{total_pv[0].text}</OrderCostItemTitle>
            <ProductCostInfo>
              <StyledSpan data-qa={`orderPV-${orderNumber}`} textAlign="right" width="100%">
                {pvTotal}
              </StyledSpan>
            </ProductCostInfo>
          </OrderCostInfo>
        )}
      </OrderCostWrapper>
      <ReorderButton data-qa={`reorderButton-${orderNumber}`} content="Order Again" orderNumber={orderNumber} />
    </>
  )
}

export default React.memo(OrderCost)
