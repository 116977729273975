import React from 'react'
import { Link } from 'gatsby'
// Styles
import {
  Wrapper,
  CardItem,
  CategoryTitle,
  ImageOverlay,
} from './ProductCategory.styles'

import categoryImg from '../../images/category/category_test.jpeg'

const ProductCategory = category => {
  return (
    <Wrapper>
      <CardItem
        as={Link}
        backgroundimg={category?.image ?? categoryImg}
        to={`/products/type/${category.url_key}`}
        data-qa={`category-${category.name}`}
      >
        <ImageOverlay>
          <CategoryTitle>{category.name.toUpperCase()}</CategoryTitle>
        </ImageOverlay>
      </CardItem>
    </Wrapper>
  )
}

export default ProductCategory
