exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-add-card-tsx": () => import("./../../../src/pages/add-card.tsx" /* webpackChunkName: "component---src-pages-add-card-tsx" */),
  "component---src-pages-add-update-shipping-js": () => import("./../../../src/pages/add-update-shipping.js" /* webpackChunkName: "component---src-pages-add-update-shipping-js" */),
  "component---src-pages-buy-again-js": () => import("./../../../src/pages/buy-again.js" /* webpackChunkName: "component---src-pages-buy-again-js" */),
  "component---src-pages-cart-tsx": () => import("./../../../src/pages/cart.tsx" /* webpackChunkName: "component---src-pages-cart-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-order-history-tsx": () => import("./../../../src/pages/order-history.tsx" /* webpackChunkName: "component---src-pages-order-history-tsx" */),
  "component---src-pages-payment-info-tsx": () => import("./../../../src/pages/payment-info.tsx" /* webpackChunkName: "component---src-pages-payment-info-tsx" */),
  "component---src-pages-products-benefit-js": () => import("./../../../src/pages/products/benefit.js" /* webpackChunkName: "component---src-pages-products-benefit-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-products-product-detail-js": () => import("./../../../src/pages/products/product-detail.js" /* webpackChunkName: "component---src-pages-products-product-detail-js" */),
  "component---src-pages-products-type-js": () => import("./../../../src/pages/products/type.js" /* webpackChunkName: "component---src-pages-products-type-js" */),
  "component---src-pages-profile-tsx": () => import("./../../../src/pages/profile.tsx" /* webpackChunkName: "component---src-pages-profile-tsx" */),
  "component---src-pages-q-program-tsx": () => import("./../../../src/pages/q-program.tsx" /* webpackChunkName: "component---src-pages-q-program-tsx" */),
  "component---src-pages-register-tsx": () => import("./../../../src/pages/register.tsx" /* webpackChunkName: "component---src-pages-register-tsx" */),
  "component---src-pages-science-tsx": () => import("./../../../src/pages/science.tsx" /* webpackChunkName: "component---src-pages-science-tsx" */),
  "component---src-pages-shipping-info-js": () => import("./../../../src/pages/shipping-info.js" /* webpackChunkName: "component---src-pages-shipping-info-js" */)
}

