import firebase from 'firebase/app'

import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/database'

import environment from '../utils/environment'

const firebaseConfig = environment.FIREBASE

export default new Proxy(
  {
    get database() {
      return firebase.database()
    },
    get firestore() {
      return firebase.firestore()
    },
    get auth() {
      return firebase.auth()
    },
    providers: {
      get google() {
        return new firebase.auth.GoogleAuthProvider()
      },
      get facebook() {
        return new firebase.auth.FacebookAuthProvider()
      },
      get apple() {
        return new firebase.auth.OAuthProvider('apple.com')
      },
    },
  },
  {
    get: function (target, name) {
      if (!firebase.apps.length) {
        firebase.initializeApp(firebaseConfig)
      }

      return target[name]
    },
  }
)
