import { Modal, Header, Dropdown, Checkbox } from 'semantic-ui-react'
import styled from 'styled-components'

export const StyledModal = styled(Modal)`
  width: 90% !important;

  @media ${({ theme }) => theme.device.sm} {
    width: 600px !important;
  }

  &&& {
    i.close {
      top: 0;
      right: 0;
      color: ${({ theme }) => theme.colors.black};
    }
    .content {
      padding: 2rem 3rem;
    }
  }
`

export const StyledHeader = styled(Header)`
  background: #d8d8d8 !important;
  text-align: center;
  font-size: 18px !important;
  font-weight: bold !important;

  @media ${({ theme }) => theme.device.sm} {
    font-size: 22px !important;
  }
`

export const MarketLabel = styled.div`
  font-size: 16px;
  font-weight: bold;

  @media ${({ theme }) => theme.device.sm} {
    font-size: 19px;
  }
`

export const MarketDropdown = styled(Dropdown)`
  margin-top: 10px;
  font-size: 16px;

  @media ${({ theme }) => theme.device.sm} {
    font-size: 19px;
  }
`

export const LanguageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2em 0;
`

export const LanguageOption = styled(Checkbox)`
  &&& {
    display: flex;
    justify-content: flex-start;
    padding: 5px;
    font-size: 1.2em;
  }
`

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`
