import React, { useState } from 'react'
import { navigate } from 'gatsby'
import styled from 'styled-components'
import { Primary } from '.'
import { useCart } from '../../context/CartContext'

const StyledButton = styled(Primary)`
  &&& {
    box-shadow: none;
    margin: 1em auto;
    width: 200px;
  }
`

interface ReorderProps {
  content: string
  orderNumber: string
}

const ReorderButton = ({ content, orderNumber }: ReorderProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const {
    manageCart: { handleReorderItems },
  } = useCart()
  function handleClick() {
    setIsLoading(true)
    handleReorderItems(orderNumber)
      .then(() => navigate('/cart'))
      .catch(err => console.log(err))
  }

  return (
    <StyledButton onClick={handleClick} content={content} loading={isLoading} />
  )
}

export default ReorderButton
