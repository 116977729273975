import React, { useState, useEffect, useContext } from 'react'
// Services
import firebase from '../services/firebase'
import { QService } from '../services/q-services'
import { Magento } from '../services/magento'
// Helpers
import { loginWithQServices, loginWithMagento } from '../utils/loginHelpers'
import toast from 'react-hot-toast'
import { Logger } from '../utils/logger'
import environment from '../utils/environment'

const AuthContext = React.createContext()

export const useAuthContext = () => useContext(AuthContext)

const AuthProvider = ({ children }) => {
  const [isEventSite] = useState(environment.IS_EVENT_SITE)
  const [loading, setLoading] = useState(true)
  const [firebaseUser, setFirebaseUser] = useState(null)
  const [qUser, setQUser] = useState(null)
  const [isUserAuthenticated, setIsAuthenticated] = useState(false)
  const [isQAccountVerified, setIsQAccountVerified] = useState(true)
  const [isAmbassador, setIsAmbassador] = useState(false)
  const [isPc, setIsPc] = useState(false)
  const [isRetail, setIsRetail] = useState(false)
  const [apiError, setApiError] = useState(null)
  const [refreshStore, setRefreshStore] = useState(false)
  const [isFirstTimeLogin, setFirstTimeLogin] = useState(false)

  const handleSetFirebaseUser = () => {
    const user = firebase.auth.currentUser.toJSON()
    const token = user.stsTokenManager.accessToken
    setFirebaseUser(user)
    localStorage.setItem('firebaseUser', JSON.stringify(user, null, 2))
    return { user, token }
  }

  const handleSetFirebaseToken = token => {
    QService.setToken(token)
    localStorage.setItem('firebaseToken', token)
  }

  const handleSetMagentoToken = token => {
    Magento.User.setToken(token)
    localStorage.setItem('magentoToken', token)
  }

  // const getUserFirebaseToken = async () =>
  //   await firebase.auth.currentUser.getIdToken(true)

  const firebaseLogin = async (email, password) => {
    setLoading(true)
    return await firebase.auth
      .signInWithEmailAndPassword(email, password)
      .catch(err => {
        Logger.log(err)
        setApiError(err.message)
        setLoading(false)
      })
  }

  const firebaseLoginNewUser = async (email, password) => {
    setLoading(true)
    return await firebase.auth
      .createUserWithEmailAndPassword(email, password)
      .catch(error => {
        Logger.log(error)
        setApiError(error.message)
        setLoading(false)
        return error
      })
  }

  const firebaseLoginWithProvider = service => {
    setLoading(true)
    const provider = firebase.providers[`${service}`]
    if (service === 'apple') {
      provider.addScope('name')
      provider.addScope('email')
    } else {
      provider.addScope(service === 'google' ? 'profile' : 'public_profile')
      provider.addScope('email')
    }
    return firebase.auth.signInWithRedirect(provider)
  }

  const handleUserLogout = () => {
    localStorage.clear()
    setFirebaseUser(null)
    setQUser(null)
    setIsAuthenticated(false)
    setIsQAccountVerified(true)
    setIsAmbassador(false)
    setIsPc(false)
    setIsRetail(false)
    Magento.User.unSetToken()
    setRefreshStore(true)
    return firebase.auth.signOut()
  }

  const resetPassword = email => firebase.auth.sendPasswordResetEmail(email)

  // const updateEmail = email => firebase.auth.currentUser.updateEmail(email)

  // const updatePassword = password =>
  //   firebase.auth.currentUser.updatePassword(password)

  useEffect(() => {
    setIsAuthenticated(false)
    setLoading(true)
    firebase.auth.getRedirectResult().catch(err => {
      if (err?.code === 'auth/web-storage-unsupported') {
        toast.error(
          'Allow third-party cookies in your browser for this site to login using google or facebook',
          { duration: 5000 }
        )
      }
    })
    firebase.auth.onAuthStateChanged(async user => {
      if (user) {
        let currentFirebaseUser = handleSetFirebaseUser()
        const currentFirebaseUserToken = currentFirebaseUser.token

        // firebase token is added to auth header for all subsequent calls to QS
        handleSetFirebaseToken(currentFirebaseUserToken)

        let qUserData = await loginWithQServices(setIsQAccountVerified)

        if (!qUserData) {
          setLoading(false)
          return
        }

        // will run only if associate is returned from loginWithQServices
        const {
          firstName,
          lastName,
          legacyAssociateId,
          associateId,
          associateType,
        } = qUserData

        if (associateType === 'AMBASSADOR') setIsAmbassador(true)
        if (associateType === 'PREFERRED') setIsPc(true)
        if (associateType === 'RETAIL') setIsRetail(true)

        // get logged in user's upline id
        let uplineUserId = await QService.getUserTreeNode({
          legacyAssociateId,
        }).then(({ treeNodeFor }) => treeNodeFor.uplineAssociateId)

        // get upline user's profile
        let uplineUserData = await QService.getUserProfile({
          associateId: uplineUserId,
        }).then(({ associates }) => associates[0])

        // add upline user's data to logged in user
        qUserData.uplineUser = uplineUserData

        setQUser(qUserData)
        // update firebase user display name with QS login data
        if (!currentFirebaseUser.user.displayName) {
          firebase.auth.currentUser
            .updateProfile({
              displayName: `${firstName} ${lastName}`,
            })
            .then(handleSetFirebaseUser)
        }

        try {
          const magentoToken = await loginWithMagento(
            currentFirebaseUserToken,
            firstName,
            lastName,
            associateId,
            legacyAssociateId,
            isFirstTimeLogin
          )

          handleSetMagentoToken(magentoToken)
          setIsAuthenticated(true)
        } catch (err) {
          Logger.log(err?.message)
        }
        setLoading(false)
      } else {
        setLoading(false)
      }
    })
  }, [isFirstTimeLogin])

  const value = {
    loading,
    isUserAuthenticated,
    isQAccountVerified,
    isAmbassador,
    isPc,
    isRetail,
    apiError,
    isEventSite,
    setApiError,
    setIsQAccountVerified,
    firebaseLogin,
    firebaseLoginNewUser,
    firebaseLoginWithProvider,
    handleUserLogout,
    resetPassword,
    setQUser,
    setRefreshStore,
    setFirstTimeLogin,
    firebaseUser,
    qUser,
    refreshStore,
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export default AuthProvider
