import React from 'react'
import { Link } from 'gatsby'

import {
  Wrapper,
  StyledMessage,
  StyledHeader,
} from './ErrorMessage.styles'

const ErrorMessage = ({ message, cb }) => {
  return (
    <Wrapper>
      <StyledMessage
        error
        onDismiss={cb}
        header={<StyledHeader>Error</StyledHeader>}
        content={
          <>
            <p>{message}</p>
            <Link to="/contact-us">Need help? Click here!</Link>
          </>
        }
      />
    </Wrapper>
  )
}

export default ErrorMessage
