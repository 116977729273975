/**
 * If a product is part of the Exclude PC category, PC users should not be able to see it
 * If a product is part of the Retail category, Retail users should not be able to see it
 * If a product is part of the Packs category, Retail users should not be able to see it
 */
export const filterProducts = (products, qUser) => {
  if (!products) return []
  const associateType = qUser?.associateType ?? 'NOT LOGGED IN'
  products = products.map(product => {
    product.categoryNames = product.categories.map(({ name }) => name)
    return product
  })
  switch (associateType) {
    case 'AMBASSADOR':
      return products
    case 'PREFERRED':
      return products.filter(
        ({ categoryNames }) => !categoryNames.includes('Exclude PC')
      )
    default:
      return products.filter(
        ({ categoryNames }) =>
          !categoryNames.some(
            category => category === 'Exclude Retail' || category === 'Packs'
          )
      ) // if you're not logged in, or not retail, you can't see packs
  }
}
