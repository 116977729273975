import React, { useState, useEffect, useContext } from 'react'
import toast from 'react-hot-toast'
import { truncate } from 'lodash'
import axios from 'axios'
import StackTrace from 'stacktrace-js'
// Contexts
import { useAuthContext } from './AuthContext'
import { useStore } from './StoreContext'
// Types

// Services
import { Magento } from '../services/magento'
import { QService } from '../services/q-services'
// Utils
import { Logger } from '../utils/logger'
import environment from '../utils/environment'
import { displayOutOfStockError, handleOutOfStock } from '../utils/cartHelpers'

const SLACK = environment.SLACK_WEBHOOK_URL

const Cart = React.createContext({})

export const useCart = () => useContext(Cart)

const CartProvider = ({ children }) => {
  const [cartData, setCartData] = useState(null)
  const [placingOrder, setPlacingOrder] = useState(false)

  const { isUserAuthenticated } = useAuthContext()
  const { getSelectedCard } = useStore()

  // NOTE - Need to add the logic here for createEmptyCart mutation
  useEffect(() => {
    if (!isUserAuthenticated) {
      setCartData(null)
      return
    }

    const getOrCreateCart = async () => {
      await Magento.Cart.getActiveCartOrCreateNew()
        .then(({ customerCart }) => {
          handleBuildCartData(customerCart)
        })
        .catch(e => {
          try {
            const { customerCart } = handleOutOfStock(e)
            handleBuildCartData(customerCart)
            displayOutOfStockError(customerCart)
          } catch (e) {
            Logger.log(e)
          }
        })
    }
    getOrCreateCart()
  }, [isUserAuthenticated])

  const handleBuildCartData = cart => {
    let totalSavings = 0
    cart.items = cart.items.filter(item => !!item) // when there are errors, the product item array has nulls we want to remove
    cart.items.map(item => {
      const {
        product: { price_range },
        quantity,
      } = item

      const basePrice = price_range?.maximum_price?.regular_price?.value ?? ''
      const baseTotal = basePrice * quantity
      const finalPrice = price_range?.maximum_price?.final_price?.value ?? ''
      const finalTotal = finalPrice * quantity
      const savings = baseTotal - finalTotal

      totalSavings += savings
    })
    setCartData({ ...cart, total_savings: totalSavings })
  }

  const handleUpdateCartData = () =>
    Magento.Cart.getActiveCartOrCreateNew()
      .then(({ customerCart }) => handleBuildCartData(customerCart))
      .catch(e => {
        try {
          const { customerCart } = handleOutOfStock(e)
          handleBuildCartData(customerCart)
          displayOutOfStockError(customerCart)
        } catch (e) {
          Logger.log(e)
        }
      })

  const addItemToCart = async (item, quantity = 1) => {
    const itemObj = {
      sku: item.sku,
      quantity,
    }

    const cartObj = {
      cartId: cartData?.id,
      cartItems: [itemObj],
    }

    await new Promise((resolve, reject) => {
      Magento.Cart.addProductsToCart(cartObj)
        .then(handleUpdateCartData)
        .then(() => {
          resolve()
          toast.success(
            `ADDED! ${truncate(item.name, { length: 20 })} x${quantity}`
          )
        })
        .catch(err => {
          reject(err)
          toast.error(err.response.errors[0].message)
        })
    })
  }

  const addConfigurableProductToCart = async (item, quantity = 1) => {
    const itemObj = {
      sku: item.sku,
      selected_options: [item.optionUid],
      quantity,
    }

    const cartObj = {
      cartId: cartData?.id,
      cartItems: [itemObj],
    }

    await new Promise((resolve, reject) => {
      Magento.Cart.addProductsToCart(cartObj)
        .then(handleUpdateCartData)
        .then(() => {
          resolve()
          toast.success(
            `ADDED! ${truncate(item.name, { length: 20 })} x${quantity}`
          )
        })
        .catch(err => {
          reject(err)
          toast.error(err.response.errors[0].message)
        })
    })
  }

  const addBundleProductToCart = async (product, quantity = 1) => {
    const bundledOptions = product.items.map(item => ({
      id: item.option_id,
      quantity: item.options[0].quantity,
      value: [`${item.options[0].id}`],
    }))

    const productObj = {
      data: {
        sku: product.sku,
        quantity,
      },
      bundle_options: bundledOptions,
    }

    const cartObj = {
      cart_id: cartData.id,
      cart_items: [productObj],
    }

    await new Promise((resolve, reject) => {
      Magento.Cart.addBundleProductsToCart(cartObj)
        .then(handleUpdateCartData)
        .then(() => {
          resolve()
          toast.success(
            `ADDED! ${truncate(product.name, { length: 20 })} x${quantity}`
          )
        })
        .catch(err => {
          reject(err)
          toast.error(err.response.errors[0].message)
        })
    })
  }

  const removeItemFromCart = async (itemUid, name) => {
    const cartObj = {
      cart_id: cartData?.id,
      cart_item_uid: itemUid,
    }

    await new Promise((resolve, reject) => {
      Magento.Cart.removeItemFromCart(cartObj)
        .then(handleUpdateCartData)
        .then(() => {
          resolve()
          toast.success(`REMOVED! ${truncate(name, { length: 20 })}`, {
            icon: '❌',
          })
        })
        .catch(err => {
          reject(err)
          toast.error(err.response.errors[0].message)
        })
    })
  }

  const updateCartItems = async (data, add = true) => {
    const { product, uid, quantity } = data
    const itemObj = {
      cart_item_uid: uid,
      quantity: add ? quantity + 1 : quantity - 1,
    }
    const cartObj = {
      cart_id: cartData?.id,
      cart_items: [itemObj],
    }

    await new Promise((resolve, reject) => {
      Magento.Cart.updateItemsInCart(cartObj)
        .then(handleUpdateCartData)
        .then(() => {
          resolve()
          toast.success(
            `${add ? 'ADDED!' : 'REMOVED!'} ${truncate(product.name, {
              length: 20,
            })}`,
            {
              icon: add ? '✅' : '❌',
            }
          )
        })
        .catch(err => {
          reject(err)
          toast.error(err.response.errors[0].message)
        })
    })
  }

  const handleReorderItems = async (orderNumber: string) =>
    await Magento.Cart.reorderItems({ orderNumber })
      .then(handleUpdateCartData)
      .catch(err => Logger.log(err))

  const addCouponToCart = async code => {
    const cartObj = {
      cart_id: cartData.id,
      coupon_code: code,
    }
    return await Magento.Cart.applyCouponToCart(cartObj)
  }

  const removeCouponFromCart = async () => {
    return await Magento.Cart.removeCouponFromCart({ cart_id: cartData.id })
  }

  const placeOrder = async () => {
    setPlacingOrder(true)
    try {
      // setShippingMethodOnCart
      const method = cartData?.shipping_addresses[0].available_shipping_methods
      if (method.length) {
        const shippingMethodObj = {
          cart_id: cartData.id,
          shipping_methods: [
            {
              carrier_code: method[0].carrier_code,
              method_code: method[0].method_code,
            },
          ],
        }
        await Magento.Cart.setShippingMethodOnCart(shippingMethodObj)
          .then(handleUpdateCartData)
          .then(() => Logger.log('shipping method set'))
          .catch(err => Logger.log(err))
      }

      // setPaymentMethodOnCart
      const cartPaymentMethods = cartData?.available_payment_methods[0]
      if (cartPaymentMethods) {
        const { code } = cartPaymentMethods
        const paymentMethodObj = {
          cart_id: cartData.id,
          payment_method: {
            code,
          },
        }
        await Magento.Cart.setPaymentMethodOnCart(paymentMethodObj)
          .then(handleUpdateCartData)
          .then(() => Logger.log('payment method set'))
          .catch(err => Logger.log(err))
      }
    } catch (error) {
      Logger.log(error)
    }

    const { placeOrder } = await Magento.Cart.placeOrder({
      cart_id: cartData.id,
    })

    let orderNumber: string, status: string, error: any

    const magentoOrderId = placeOrder.order.order_number
    orderNumber = magentoOrderId
    let ordersArr = await Magento.User.getCustomerOrders().then(
      ({ customerOrders }) => customerOrders.items
    )
    let orderInfo = ordersArr.find(
      order => order.order_number === magentoOrderId
    )
    status = orderInfo.status
    // TODO - Import this function from Store Context
    const { creditCardGuid } = getSelectedCard()
    const nexioObj = {
      magentoOrderId: orderInfo.id,
      creditCardGuid,
    }

    // handle payment response from Nexio & Q Services
    try {
      let { value } = await QService.nexioPostTransaction(nexioObj)
      if (value !== 'Success') {
        error = JSON.parse(value.replace('\u0022', '"'))
      }
    } catch (err) {
      StackTrace.get(err)
        .then(data => {
          const stackErr = JSON.stringify(data)
          const qErr = JSON.stringify(err)
          return axios.post(
            SLACK,
            {
              text: `=======ORDER FAILED, STATUS PENDING=======\n\nStack Error:\n${stackErr}\n\nQ Services Error:\n${qErr}`,
            },
            {
              headers: {
                'Content-Type': 'application/json',
              },
            }
          )
        })
        .catch(err => console.log(err))

      error = true
    }

    handleUpdateCartData()
    setPlacingOrder(false)

    return { orderNumber, error, status }
  }

  const manageCart = {
    addItemToCart,
    addBundleProductToCart,
    addConfigurableProductToCart,
    addCouponToCart,
    handleReorderItems,
    removeCouponFromCart,
    removeItemFromCart,
    updateCartItems,
    placeOrder,
  }
  return (
    <Cart.Provider
      value={{
        cartData,
        manageCart,
        placingOrder,
        handleUpdateCartData,
      }}
    >
      {children}
    </Cart.Provider>
  )
}

export default CartProvider
