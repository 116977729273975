// Services
import { QService } from '../services/q-services'
import { Magento } from '../services/magento'
import { Logger } from './logger'
import { updateCustomerGroup } from '../services/azureFunctions'

async function loginWithQServices(setIsQAccountVerified) {
  return await QService.login()
    .then(({ loginUser }) => {
      if (loginUser.loginStatus === 'VERIFICATION_NEEDED') {
        setIsQAccountVerified(false)
        return null
      }
      setIsQAccountVerified(true)
      return loginUser.associate
    })
    .catch(err => Logger.log(err))
}

async function loginWithMagento(
  firebaseToken,
  firstName,
  lastName,
  associateId,
  legacyAssociateId,
  isFirstTimeLogin
) {
  if (!firebaseToken) return
  const magentoUserData = {
    jwt_token: firebaseToken,
    first_name: firstName,
    last_name: lastName,
    associate_id: associateId,
    legacy_associate_id: legacyAssociateId,
  }
  try {
    const { getCustomerToken } =
      await Magento.User.getMagentoTokenFromFirebaseToken(magentoUserData)
    if (getCustomerToken.status === 'success') {
      if(isFirstTimeLogin) await updateCustomerGroup(getCustomerToken.customer_token, firebaseToken)
      return getCustomerToken.customer_token
    } else {
      Logger.log(getCustomerToken.message)
    }
  } catch (err) {
    Logger.log(err)
  }
}

export { loginWithQServices, loginWithMagento }
