/* eslint-disable jsx-a11y/no-autofocus */

import React, { useState } from 'react'
import { Modal, Header } from 'semantic-ui-react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
// Hooks
import useShowError from '../../hooks/useShowError'
// Components
import { Primary as SubmitButton, Warning as CancelButton } from '../'
// Styles
import {
  Logo,
  StyledModal,
  StyledForm,
  StyledInput,
  Container,
  ErrorMsg,
} from './Login.styles'
// Utils
import { existingCustomer } from '../../utils/validations'

import { usePrismic } from '../../context/PrismicContext'

const ExistingQ = ({ isOpen, getUserCallback, cancel }) => {
  const {
    prismicData: { prismicExistingQModal },
  } = usePrismic()

  const [open, setOpen] = useState(isOpen)

  const [error, setError, showError] = useShowError()

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(existingCustomer),
  })

  const resetAndShowError = message => {
    reset()
    setError(message)
  }

  const onSubmit = data => {
    getUserCallback(data.customer, resetAndShowError)
  }

  const handleClose = () => {
    cancel()
    setOpen(false)
  }

  const {
    logo,
    header,
    placeholder,
    continue: continueText,
    cancel: cancelText,
  } = prismicExistingQModal

  return (
    <StyledModal
      centered={false}
      onClose={handleClose}
      open={open}
      closeOnDimmerClick={false}
      dimmer="blurring"
    >
      <Container direction="column">
        <Logo src={logo.url} />
        <Modal.Description>
          <Header textAlign="center">{header[0].text}</Header>
        </Modal.Description>
        {error && showError()}
        <Container direction="column">
          <StyledForm onSubmit={handleSubmit(onSubmit)}>
            <ErrorMsg>{errors.customer?.message}</ErrorMsg>
            <StyledInput
              {...register('customer')}
              placeholder={placeholder[0].text}
              onFocus={() => setError('')}
              autoFocus
            />
            <SubmitButton type="submit">{continueText[0].text}</SubmitButton>
            <CancelButton onClick={cancel}>{cancelText[0].text}</CancelButton>
          </StyledForm>
        </Container>
      </Container>
    </StyledModal>
  )
}

export default ExistingQ
