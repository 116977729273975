import React, { useState, useEffect, useContext } from 'react'
// Contexts & Hooks
import { useAuthContext } from './AuthContext'
import useOrderHistory from '../hooks/useOrderHistory'
// Types
import { StoreContextType } from '../types/contexts/StoreContextTypes'
import { CreditCard } from '../types/PaymentsTypes'
// Services
import { Magento } from '../services/magento'
import { QService } from '../services/q-services'
// Utils
import { Logger } from '../utils/logger'
import { filterProducts } from '../utils/productHelpers'
import { getStoreView, saveStoreView } from '../utils/i18n/stores'
// Data
import { eventCategoryProducts, categoryProducts } from '../data/categories'

const Store = React.createContext<Partial<StoreContextType>>({})

export const useStore = () => useContext(Store)

const MDEFAULTCARD = 'mdefaultcard'

const defaultStoreState = {
  storeData: {},
  isLoadingStore: true,
}

// NOTE - Store provider should handle store data and user data
const StoreProvider = ({ children }) => {
  const [isLoadingLandingPage, setIsLoadingLandingPage] = useState(true)
  const [{ storeData, isLoadingStore }, setState] = useState(defaultStoreState)
  const [landingPageData, setLandingPageData] = useState({})
  const [userData, setUserData] = useState({})
  const [cardsOnFile, setCardsOnFile] = useState<CreditCard[]>([])

  const { isUserAuthenticated, qUser, refreshStore, loading, isEventSite } =
    useAuthContext()

  const [
    handleGetNextPageOrders,
    handleGetPageDataAndOrders,
    ordersData,
    ordersPageInfo,
    isLoadingOrders,
    isLoadingMoreOrders,
  ] = useOrderHistory()

  Magento.User.setStoreView(
    getStoreView(localStorage, navigator.language).store_code
  )

  useEffect(() => {
    if (loading) return
    if (isUserAuthenticated) {
      handleLoadLandingPage(qUser)
      handleLoadStore(qUser)
    } else {
      handleLoadLandingPage(null)
      handleLoadStore(null)
    }
  }, [refreshStore, isUserAuthenticated, loading])

  useEffect(() => {
    if (!isUserAuthenticated) {
      setUserData(null)
      setCardsOnFile([])
      return
    }

    const getUserInfo = async () => {
      try {
        await Magento.User.basicInformation()
          .then(({ customer }) => setUserData(customer))
          .catch(err => Logger.log(err))
      } catch (error) {
        console.log(error)
      } finally {
        let cardsOnFile = await QService.getCardsOnFile(qUser.associateId)
        setCardsOnFile(cardsOnFile)
      }
    }
    getUserInfo()
  }, [isUserAuthenticated])

  const handleChangeStore = (storeViewCode: string) => {
    saveStoreView(localStorage, storeViewCode)
    window.location.reload()
  }

  const handleLoadLandingPage = async qUser => {
    setIsLoadingLandingPage(true)

    await Magento.Store.getLandingPageData()
      .then(({ benefit, type, featuredProducts }) => {
        const filteredFeaturedProducts = filterProducts(
          featuredProducts.items[0].products.items,
          qUser
        )
        return {
          benefit: benefit.items[0],
          type: type.items[0],
          'featured-products': filteredFeaturedProducts,
        }
      })
      .then(data => {
        setLandingPageData({ ...data })
        setIsLoadingLandingPage(false)
      })
  }

  const handleLoadStore = async qUser => {
    setState({
      storeData: {},
      isLoadingStore: true,
    })

    await Magento.Store.getAllProducts()
      .then(({ products }) => {
        const productsArr = filterProducts(products.items, qUser)
        let categories = {}

        if (isEventSite) {
          categories = eventCategoryProducts()
        } else {
          categories = categoryProducts()
        }

        productsArr.forEach(product => {
          product.categories.forEach((category, i) => {
            if (!!category.breadcrumbs) {
              const path = category.url_path.split('/')
              const parent = path[0]
              const child = path[1]

              if (parent === 'type') {
                if (child in categories[parent].subCategories) {
                  categories[parent].subCategories[child].products.push(product)
                  categories[parent].subCategories[child] = {
                    ...categories[parent].subCategories[child],
                    ...category,
                  }
                } else {
                  return
                }
              } else {
                if (child in categories[parent].subCategories) {
                  categories[parent].subCategories[child].products.push(product)
                } else {
                  categories[parent].subCategories[child] = {
                    ...category,
                    products: [product],
                  }
                }
              }
            } else {
              const catKey = category.url_key

              if (catKey in categories) {
                categories[catKey].products.push(product)
                categories[catKey] = {
                  ...categories[catKey],
                  ...category,
                }
              } else {
                categories[catKey] = {
                  ...category,
                  products: [product],
                  subCategories: [],
                }
              }
            }
          })
        })

        return categories
      })
      .then(storeData => {
        setState({
          storeData,
          isLoadingStore: false,
        })
      })
  }

  const handleUpdateUserData = () =>
    Magento.User.basicInformation()
      .then(({ customer }) => setUserData(customer))
      .catch(err => Logger.log(err))

  const addCardToFile = async (card: CreditCard, saveToFile: boolean) => {
    updateSelectedCard(card)
    setCardsOnFile([...cardsOnFile, card])
    if (saveToFile) await QService.saveCardData(qUser.associateId, card)
  }

  const getSelectedCard = () => {
    try {
      const defaultCard =
        typeof window !== 'undefined' && localStorage.getItem(MDEFAULTCARD)
      return defaultCard ? JSON.parse(defaultCard) : cardsOnFile[0] || {}
    } catch (error) {
      return {}
    }
  }

  const updateSelectedCard = (selectedCard: CreditCard) => {
    localStorage.setItem(MDEFAULTCARD, JSON.stringify(selectedCard))
  }

  return (
    <Store.Provider
      value={{
        userData,
        cardsOnFile,
        landingPageData,
        isLoadingLandingPage,
        storeData,
        ordersData,
        ordersPageInfo,
        isLoadingOrders,
        isLoadingMoreOrders,
        isLoadingStore,
        handleGetNextPageOrders,
        handleGetPageDataAndOrders,
        handleChangeStore,
        handleUpdateUserData,
        updateSelectedCard,
        getSelectedCard,
        addCardToFile,
      }}
    >
      {children}
    </Store.Provider>
  )
}

export default StoreProvider
