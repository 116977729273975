import { gql } from 'graphql-request'

// ------------------------------ CUSTOMER ------------------------------

export const CREATE_CUSTOMER = gql`
  mutation newCustomer(
    $firstname: String!
    $lastname: String!
    $email: String!
    $password: String!
  ) {
    createCustomer(
      input: {
        firstname: $firstname
        lastname: $lastname
        email: $email
        password: $password
      }
    ) {
      customer {
        email
      }
    }
  }
`

export const GET_FIREBASE_TOKEN = gql`
  mutation getFirebaseToken($email: String!, $password: String!) {
    getFireBaseToken(input: { email: $email, password: $password }) {
      status
      message
      firebase_token
    }
  }
`

export const GET_MAGENTO_TOKEN_FROM_FIREBASE_TOKEN = gql`
  mutation getCustomerToken(
    $jwt_token: String!
    $first_name: String!
    $last_name: String!
    $associate_id: String!
    $legacy_associate_id: String!
  ) {
    getCustomerToken(
      input: {
        jwt_token: $jwt_token
        first_name: $first_name
        last_name: $last_name
        associate_id: $associate_id
        legacy_associate_id: $legacy_associate_id
      }
    ) {
      status
      message
      customer_token
      isNewCustomer
    }
  }
`

export const UPDATE_CUSTOMER_NAME = gql`
  mutation updateUser($firstname: String!, $lastname: String!) {
    updateCustomerV2(input: { firstname: $firstname, lastname: $lastname }) {
      customer {
        email
        firstname
        lastname
      }
    }
  }
`

export const GENERATE_CUSTOMER_TOKEN = gql`
  mutation getToken($email: String!, $password: String!) {
    generateCustomerToken(email: $email, password: $password) {
      token
    }
  }
`

export const CREATE_CUSTOMER_ADDRESS = gql`
  mutation createCustomerAddress($input: CustomerAddressInput!) {
    createCustomerAddress(input: $input) {
      id
      region {
        region
        region_code
        region_id
      }
      country_code
      street
      telephone
      postcode
      city
      default_shipping
      default_billing
    }
  }
`

export const UPDATE_CUSTOMER_ADDRESS = gql`
  mutation updateCustomerAddress($id: Int!, $input: CustomerAddressInput!) {
    updateCustomerAddress(id: $id, input: $input) {
      id
      region {
        region
        region_code
        region_id
      }
      country_code
      street
      telephone
      postcode
      city
      default_shipping
      default_billing
    }
  }
`

export const DELETE_CUSTOMER_ADDRESS = gql`
  mutation deleteCustomerAddress($id: Int!) {
    deleteCustomerAddress(id: $id)
  }
`

// ------------------------------ CART ------------------------------

// This is used for a GUEST user
export const CREATE_EMPTY_CART = gql`
  mutation {
    createEmptyCart
  }
`

export const CLEAR_CUSTOMER_CART = gql`
  mutation clearCart($cartUid: String!) {
    clearCustomerCart(cartUid: $cartUid) {
      status
    }
  }
`

export const REORDER_ITEMS = gql`
  mutation reorderItems($orderNumber: String!) {
    reorderItems(orderNumber: $orderNumber) {
      cart {
        id
        items {
          uid
          product {
            sku
          }
          quantity
          prices {
            price {
              value
            }
          }
        }
      }
      userInputErrors {
        code
        message
        path
      }
    }
  }
`

export const ADD_PRODUCTS_TO_CART = gql`
  mutation addProductsToCart($cartId: String!, $cartItems: [CartItemInput!]!) {
    addProductsToCart(cartId: $cartId, cartItems: $cartItems) {
      cart {
        items {
          uid
        }
      }
    }
  }
`

export const ADD_BUNDLE_PRODUCTS_TO_CART = gql`
  mutation addBundleProductsToCart(
    $cart_id: String!
    $cart_items: [BundleProductCartItemInput!]!
  ) {
    addBundleProductsToCart(
      input: { cart_id: $cart_id, cart_items: $cart_items }
    ) {
      cart {
        items {
          uid
        }
      }
    }
  }
`

export const UPDATE_CART_ITEMS = gql`
  mutation updateCartItems(
    $cart_id: String!
    $cart_items: [CartItemUpdateInput!]!
  ) {
    updateCartItems(input: { cart_id: $cart_id, cart_items: $cart_items }) {
      cart {
        items {
          uid
        }
      }
    }
  }
`

export const REMOVE_PRODUCT_FROM_CART = gql`
  mutation removeProductFromCart($cart_id: String!, $cart_item_uid: ID!) {
    removeItemFromCart(
      input: { cart_id: $cart_id, cart_item_uid: $cart_item_uid }
    ) {
      cart {
        items {
          uid
        }
      }
    }
  }
`

export const APPLY_COUPON_TO_CART = gql`
  mutation applyCouponToCart($cart_id: String!, $coupon_code: String!) {
    applyCouponToCart(input: { cart_id: $cart_id, coupon_code: $coupon_code }) {
      cart {
        items {
          product {
            name
          }
          quantity
        }
        applied_coupons {
          code
        }
        prices {
          grand_total {
            value
            currency
          }
        }
      }
    }
  }
`

export const REMOVE_COUPON = gql`
  mutation removeCoupon($cart_id: String!) {
    removeCouponFromCart(input: { cart_id: $cart_id }) {
      cart {
        items {
          product {
            name
          }
        }
      }
    }
  }
`

export const SET_SHIPPING_ADDRESSES_ON_CART = gql`
  mutation addShippingAddressesOnCart(
    $cart_id: String!
    $shipping_addresses: [ShippingAddressInput]!
  ) {
    setShippingAddressesOnCart(
      input: { cart_id: $cart_id, shipping_addresses: $shipping_addresses }
    ) {
      cart {
        shipping_addresses {
          firstname
          lastname
          company
          street
          city
          region {
            region_id
          }
          postcode
          telephone
          country {
            code
            label
          }
          pickup_location_code
          available_shipping_methods {
            carrier_code
            carrier_title
            method_code
            method_title
          }
        }
      }
    }
  }
`

export const SET_BILLING_ADDRESS_ON_CART = gql`
  mutation addBillingAddress(
    $cart_id: String!
    $billing_address: BillingAddressInput!
  ) {
    setBillingAddressOnCart(
      input: { cart_id: $cart_id, billing_address: $billing_address }
    ) {
      cart {
        billing_address {
          firstname
          lastname
          company
          street
          city
          region {
            code
            label
          }
          postcode
          telephone
          country {
            code
            label
          }
        }
      }
    }
  }
`

export const SET_SHIPPING_METHOD = gql`
  mutation setShippingMethod(
    $cart_id: String!
    $shipping_methods: [ShippingMethodInput!]!
  ) {
    setShippingMethodsOnCart(
      input: { cart_id: $cart_id, shipping_methods: $shipping_methods }
    ) {
      cart {
        shipping_addresses {
          selected_shipping_method {
            carrier_code
            carrier_title
            method_code
            method_title
            amount {
              value
              currency
            }
          }
        }
      }
    }
  }
`

export const SET_PAYMENT_METHOD = gql`
  mutation setPaymentMethod(
    $cart_id: String!
    $payment_method: PaymentMethodInput!
  ) {
    setPaymentMethodOnCart(
      input: { cart_id: $cart_id, payment_method: $payment_method }
    ) {
      cart {
        selected_payment_method {
          code
          title
        }
      }
    }
  }
`

export const PLACE_ORDER = gql`
  mutation placeOrder($cart_id: String!) {
    placeOrder(input: { cart_id: $cart_id }) {
      order {
        order_number
      }
    }
  }
`
