import { gql } from 'graphql-request'

// ------------------------------ STORE ------------------------------

export const GET_CATEGORIES = gql`
  query {
    categories {
      items {
        uid
        name
        children {
          uid
          name
          image
          url_key
          url_path
          children {
            uid
            name
            image
            url_key
            url_path
          }
        }
      }
    }
  }
`

export const GET_ALL_PRODUCTS = gql`
  query {
    products(search: "", pageSize: 400) {
      items {
        uid
        name
        url_key
        url_path
        sku
        meta_title
        meta_description
        meta_keyword
        categories {
          uid
          image
          url_key
          url_path
          name
          breadcrumbs {
            category_uid
            category_name
            category_url_key
          }
        }
        ... on BundleProduct {
          items {
            option_id
            uid
            required
            options {
              id
              quantity
              qty
              price
            }
          }
        }
      ... on ConfigurableProduct {
        media_gallery {
          url
          label
          position
        }
        configurable_options {
          uid
          attribute_uid
          label
          position
          use_default
          attribute_code
          values {
            value_index
            label
            uid
          }
          product_id
        }
        configurable_product_options_selection(
          configurableOptionValueUids: []
        ) {
          options_available_for_selection {
            attribute_code
            option_value_uids
          }
          media_gallery {
            url
            label
            position
          }
        }
      }
        description {
          html
        }
        short_description {
          html
        }
        price {
          regularPrice {
            amount {
              value
            }
          }
        }
        price_range {
          maximum_price {
            final_price {
              value
            }
            regular_price {
              value
            }
          }
          minimum_price {
            final_price {
              value
            }
            regular_price {
              value
            }
          }
        }
        stock_status
        pv
        image {
          url
        }
        ingredient
        suggested_use
        media_gallery {
          url
          label
          position
        }
        reviews {
          items {
            created_at
            text
            nickname
            summary
            average_rating
            ratings_breakdown {
              name
              value
            }
          }
        }
      }
    }
  }
`

export const GET_SINGLE_CATEGORY_PRODUCTS = gql`
  query getSingleCategoryProducts($url_key: String) {
    categories(filters: { url_key: { eq: $url_key } }) {
      ...singleCategoryFragment
    }
  }

  fragment singleCategoryFragment on CategoryResult {
    items {
      uid
      name
      image
      url_key
      url_path
      products(pageSize: 50) {
        ...categoryProductFragment
      }
    }
  }

  fragment categoryProductFragment on CategoryProducts {
    items {
      uid
      name
      url_key
      url_path
      sku
      meta_title
      meta_description
      meta_keyword
      categories {
        name
      }
      ... on BundleProduct {
        items {
          option_id
          uid
          required
          options {
            id
            quantity
            qty
            price
          }
        }
      }
      ... on ConfigurableProduct {
        media_gallery {
          url
          label
          position
        }
        configurable_options {
          uid
          attribute_uid
          label
          position
          use_default
          attribute_code
          values {
            value_index
            label
            uid
          }
          product_id
        }
        configurable_product_options_selection(
          configurableOptionValueUids: []
        ) {
          options_available_for_selection {
            attribute_code
            option_value_uids
          }
          media_gallery {
            url
            label
            position
          }
        }
      }
      description {
        html
      }
      short_description {
        html
      }
      price {
        regularPrice {
          amount {
            value
          }
        }
      }
      price_range {
        maximum_price {
          final_price {
            value
          }
          regular_price {
            value
          }
        }
      }
      pv
      image {
        url
      }
      media_gallery {
        url
        label
        position
      }
      reviews {
        items {
          created_at
          text
          nickname
          summary
          average_rating
          ratings_breakdown {
            name
            value
          }
        }
      }
    }
  }
`

export const GET_SINGLE_PRODUCT = gql`
  query getSingleProductByUrlKey($url_key: String) {
    products(filter: { url_key: { eq: $url_key } }) {
      items {
        uid
        name
        url_key
        url_path
        sku
        meta_title
        meta_description
        meta_keyword
        categories {
          name
        }
        ... on BundleProduct {
          items {
            option_id
            uid
            required
            options {
              id
              quantity
              qty
              price
            }
          }
        }
      ... on ConfigurableProduct {
        media_gallery {
          url
          label
          position
        }
        configurable_options {
          uid
          attribute_uid
          label
          position
          use_default
          attribute_code
          values {
            value_index
            label
            uid
          }
          product_id
        }
        configurable_product_options_selection(
          configurableOptionValueUids: []
        ) {
          options_available_for_selection {
            attribute_code
            option_value_uids
          }
          media_gallery {
            url
            label
            position
          }
        }
      }
        description {
          html
        }
        short_description {
          html
        }
        price {
          regularPrice {
            amount {
              value
            }
          }
        }
        price_range {
          maximum_price {
            final_price {
              value
            }
            regular_price {
              value
            }
          }
        }
        # inventory {
        #   qty
        # }
        pv
        image {
          url
        }
        media_gallery {
          url
          label
          position
        }
        reviews {
          items {
            created_at
            text
            nickname
            summary
            average_rating
            ratings_breakdown {
              name
              value
            }
          }
        }
      }
    }
  }
`

export const GET_LANDING_PAGE_STORE_DATA = gql`
  query getLandingPageData {
    benefit: categories(filters: { url_key: { eq: "benefit" } }) {
      ...parentCategoryFragment
    }
    type: categories(filters: { url_key: { eq: "type" } }) {
      ...parentCategoryFragment
    }
    featuredProducts: categories(
      filters: { url_key: { eq: "featured-products" } }
    ) {
      ...singleCategoryFragment
    }
  }

  fragment parentCategoryFragment on CategoryResult {
    items {
      uid
      name
      url_key
      url_path
      ...categoryChildrenFragment
    }
  }

  fragment singleCategoryFragment on CategoryResult {
    items {
      uid
      name
      url_key
      url_path
      products(pageSize: 20) {
        ...categoryProductFragment
      }
    }
  }

  fragment categoryChildrenFragment on CategoryTree {
    children {
      uid
      name
      url_key
      url_path
      image
    }
  }

  fragment categoryProductFragment on CategoryProducts {
    items {
      uid
      name
      url_key
      url_path
      sku
      meta_title
      meta_description
      meta_keyword
      stock_status
      categories {
        name
      }
      ... on BundleProduct {
        items {
          option_id
          uid
          required
          options {
            id
            quantity
            qty
            price
          }
        }
      }
      ... on ConfigurableProduct {
        media_gallery {
          url
          label
          position
        }
        configurable_options {
          uid
          attribute_uid
          label
          position
          use_default
          attribute_code
          values {
            value_index
            label
            uid
          }
          product_id
        }
        configurable_product_options_selection(
          configurableOptionValueUids: []
        ) {
          options_available_for_selection {
            attribute_code
            option_value_uids
          }
          media_gallery {
            url
            label
            position
          }
        }
      }
      description {
        html
      }
      short_description {
        html
      }
      price {
        regularPrice {
          amount {
            value
          }
        }
      }
      price_range {
        maximum_price {
          final_price {
            value
          }
          regular_price {
            value
          }
        }
      }
      pv
      image {
        url
      }
      media_gallery {
        url
        label
        position
      }
      reviews {
        items {
          created_at
          text
          nickname
          summary
          average_rating
          ratings_breakdown {
            name
            value
          }
        }
      }
    }
  }
`

export const GET_ALL_CATEGORIES_WITH_PRODUCTS = gql`
  {
    categories {
      items {
        uid
        name
        description
        children {
          uid
          name
          url_key
          url_path
          image
          products(pageSize: 500) {
            ...categoryProductFragment
          }
          children {
            uid
            name
            url_key
            url_path
            image
            product_count
            products(pageSize: 500) {
              ...categoryProductFragment
            }
          }
        }
      }
    }
  }

  fragment categoryProductFragment on CategoryProducts {
    items {
      uid
      name
      url_key
      url_path
      sku
      meta_title
      meta_description
      meta_keyword
      categories {
        name
      }
      ... on ConfigurableProduct {
        media_gallery {
          url
          label
          position
        }
        configurable_options {
          uid
          attribute_uid
          label
          position
          use_default
          attribute_code
          values {
            value_index
            label
            uid
          }
          product_id
        }
        configurable_product_options_selection(
          configurableOptionValueUids: []
        ) {
          options_available_for_selection {
            attribute_code
            option_value_uids
          }
          media_gallery {
            url
            label
            position
          }
        }
      }
      stock_status
      # inventory {
      #   qty
      #   manage_stock
      # }
      pv
      image {
        url
      }
      media_gallery {
        url
        label
        position
      }
      ingredient
      suggested_use
      reviews {
        items {
          created_at
          text
          nickname
          summary
          average_rating
          ratings_breakdown {
            name
            value
          }
        }
      }
      ... on BundleProduct {
        items {
          option_id
          uid
          required
          options {
            id
            quantity
            qty
            price
          }
        }
      }
      description {
        html
      }
      short_description {
        html
      }
      price {
        regularPrice {
          amount {
            value
          }
        }
      }
      price_range {
        maximum_price {
          final_price {
            value
          }
          regular_price {
            value
          }
        }
        minimum_price {
          final_price {
            value
          }
          regular_price {
            value
          }
        }
      }
      stock_status
      # inventory {
      #   qty
      #   manage_stock
      # }
      pv
      image {
        url
      }
      media_gallery {
        url
        label
        position
      }
      reviews {
        items {
          created_at
          text
          nickname
          summary
          average_rating
          ratings_breakdown {
            name
            value
          }
        }
      }
    }
  }
`

export const GET_AVAILABLE_COUNTRIES = gql`
  query {
    countries {
      id
      two_letter_abbreviation
      three_letter_abbreviation
      full_name_locale
      full_name_english
      available_regions {
        id
        code
        name
      }
    }
  }
`

export const GET_COUNTRY_DATA_BY_ID = gql`
  query getCountryData($id: String!) {
    country(id: $id) {
      id
      two_letter_abbreviation
      three_letter_abbreviation
      full_name_locale
      full_name_english
      available_regions {
        id
        code
        name
      }
    }
  }
`

// ------------------------------ CUSTOMER ------------------------------

export const CHECK_IF_USER_EXISTS = gql`
  query userExists($email: String!) {
    isEmailAvailable(email: $email) {
      is_email_available
    }
  }
`

export const BASIC_INFO = gql`
  {
    customer {
      firstname
      lastname
      suffix
      email
      addresses {
        id
        firstname
        lastname
        street
        city
        region {
          region_code
          region
          region_id
        }
        postcode
        country_code
        telephone
        default_shipping
      }
    }
  }
`

// NOTE - this endpoint is deprecated
export const GET_CUSTOMER_ORDERS = gql`
  query {
    customerOrders {
      items {
        order_number
        id
        created_at
        grand_total
        status
      }
    }
  }
`

export const GET_ORDER = gql`
  query GetOrders($number: String) {
    customer {
      orders(filter: { number: { eq: $number } }) {
        items {
          # add items data...
          number
        }
      }
    }
  }
`

export const GET_ORDERS_PAGE_INFO = gql`
  query {
    customer {
      orders(pageSize: 3) {
        page_info {
          current_page
          page_size
          total_pages
        }
      }
    }
  }
`

export const ORDER_HISTORY = gql`
  query OrderHistory($currentPage: Int) {
    customer {
      orders(pageSize: 3, currentPage: $currentPage) {
        page_info {
          current_page
          page_size
          total_pages
        }
        items {
          number
          id
          status
          order_date
          shipping_method
          items_eligible_for_return {
            product_name
          }
          payment_methods {
            name
            type
          }
          billing_address {
            street
            city
          }
          shipping_address {
            street
            city
          }
          shipments {
            comments {
              message
              timestamp
            }
            id
            items {
              id
              product_name
              product_sku
              quantity_shipped
              order_item {
                product_name
                product_sale_price {
                  value
                }
              }
            }
            number
            tracking {
              carrier
              number
            }
          }
          total {
            base_grand_total {
              value
            }
            discounts {
              amount {
                value
              }
              label
            }
            grand_total {
              value
              currency
            }
            shipping_handling {
              total_amount {
                value
                currency
              }
            }
            subtotal {
              value
            }
            taxes {
              amount {
                value
              }
              rate
              title
            }
            total_shipping {
              value
            }
            total_tax {
              value
            }
          }
          items {
            entered_options {
              label
              value
            }
            selected_options {
              label
              value
            }
            product_name
            product_sku
            product_url_key
            product_sale_price {
              value
              currency
            }
            quantity_ordered
            quantity_invoiced
            quantity_shipped
            eligible_for_return
          }
          invoices {
            comments {
              message
              timestamp
            }
            id
            items {
              discounts {
                amount {
                  value
                }
              }
              id
              order_item {
                product_name
                product_sale_price {
                  value
                }
              }
              product_name
              product_sale_price {
                value
              }
              quantity_invoiced
            }
            number
            total {
              base_grand_total {
                value
              }
              grand_total {
                value
              }
              shipping_handling {
                total_amount {
                  value
                  currency
                }
              }
              subtotal {
                value
              }
              taxes {
                amount {
                  value
                }
                rate
                title
              }
              total_shipping {
                value
              }
              total_tax {
                value
              }
            }
          }
        }
      }
    }
  }
`

export const CREATE_CUSTOMER_CART = gql`
  query {
    customerCart {
      id
      email
      billing_address {
        city
        country {
          code
          label
        }
        firstname
        lastname
        postcode
        region {
          code
          label
        }
        street
        telephone
      }
      shipping_addresses {
        firstname
        lastname
        street
        city
        postcode
        region {
          code
          label
        }
        country {
          code
          label
        }
        telephone
        available_shipping_methods {
          amount {
            currency
            value
          }
          available
          carrier_code
          carrier_title
          method_code
          method_title
          price_excl_tax {
            value
            currency
          }
          price_incl_tax {
            value
            currency
          }
        }
        selected_shipping_method {
          amount {
            value
            currency
          }
          carrier_code
          carrier_title
          method_code
          method_title
        }
      }
      available_payment_methods {
        code
        title
      }
      selected_payment_method {
        code
        title
      }
      applied_coupons {
        code
      }
      prices {
        grand_total {
          value
          currency
        }
        discounts {
          label
          amount {
            value
          }
        }
        subtotal_excluding_tax {
          value
        }
        subtotal_including_tax {
          value
        }
        subtotal_with_discount_excluding_tax {
          value
        }
        applied_taxes {
          label
          amount {
            value
          }
        }
      }
      items {
        uid
        prices {
          total_item_discount {
            value
          }
          price {
            value
          }
          discounts {
            label
            amount {
              value
            }
          }
        }
        product {
          name
          sku
          stock_status
          price_range {
            maximum_price {
              final_price {
                value
              }
              regular_price {
                value
              }
            }
            minimum_price {
              final_price {
                value
              }
              regular_price {
                value
              }
            }
          }
          special_price
          pv
          url_key
          image {
            url
          }
        }
        quantity
        ... on BundleCartItem {
          bundle_options {
            uid
            label
            type
            values {
              id
              label
              price
              quantity
            }
          }
        }
        ... on ConfigurableCartItem {
          configurable_options {
            configurable_product_option_uid
            option_label
            configurable_product_option_value_uid
            value_label
          }
        }
      }
      total_quantity
    }
  }
`
