import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  margin-bottom: 2em;

  a:link,
  a:visited {
    color: ${({ theme }) => theme.colors.white};
  }
`