import React, { useState, useEffect, useContext } from 'react'
import { getStoreView } from '../utils/i18n/stores'
import { graphql, useStaticQuery } from 'gatsby'
import { Loading } from '../components'

const Prismic = React.createContext()

export const usePrismic = () => useContext(Prismic)

const PrismicProvider = ({ children }) => {
  const [userLocale, setUserLocale] = useState(null)
  const [prismicLoading, setPrismicLoading] = useState(true)
  const [prismicData, setPrismicData] = useState({})

  useEffect(() => {
    const storeCode = getStoreView(localStorage, navigator.language).store_code
    if (storeCode === 'Event') {
      setUserLocale('en-us')
    } else {
      setUserLocale(storeCode.replace('_', '-').toLowerCase())
    }
  }, [])

  const allPrismicData = useStaticQuery(graphql`
    query GetAllPrismicData {
      prismicHomepage: allPrismicHomepage {
        edges {
          node {
            lang
            dataRaw
          }
        }
      }
      prismicSideMenu: allPrismicSideMenu {
        edges {
          node {
            lang
            dataRaw
          }
        }
      }
      prismicAboutPage: allPrismicAboutPage {
        edges {
          node {
            lang
            dataRaw
          }
        }
      }
      prismicProgramPage: allPrismicProgramPage {
        edges {
          node {
            lang
            dataRaw
          }
        }
      }
      prismicSciencePage: allPrismicSciencePage {
        edges {
          node {
            lang
            dataRaw
          }
        }
      }
      prismicContactUsPage: allPrismicContactUsPage {
        edges {
          node {
            lang
            dataRaw
          }
        }
      }
      prismicEnterCodeModal: allPrismicEnterCodeModal {
        edges {
          node {
            lang
            dataRaw
          }
        }
      }
      prismicExistingQModal: allPrismicExistingQModal {
        edges {
          node {
            lang
            dataRaw
          }
        }
      }
      prismicSendVerificationModal: allPrismicSendVerificationModal {
        edges {
          node {
            lang
            dataRaw
          }
        }
      }
      prismicFooter: allPrismicFooter {
        edges {
          node {
            lang
            dataRaw
          }
        }
      }
      prismicLoginPage: allPrismicLoginPage {
        edges {
          node {
            lang
            dataRaw
          }
        }
      }
      prismicProfilePage: allPrismicProfilePage {
        edges {
          node {
            lang
            dataRaw
          }
        }
      }
      prismicCartPage: allPrismicCartPage {
        edges {
          node {
            lang
            dataRaw
          }
        }
      }
      prismicProductsPage: allPrismicProductsPage {
        edges {
          node {
            lang
            dataRaw
          }
        }
      }
      prismicProductDetailPage: allPrismicProductDetailPage {
        edges {
          node {
            lang
            dataRaw
          }
        }
      }
      prismicOrderHistoryPage: allPrismicOrderHistoryPage {
        edges {
          node {
            lang
            dataRaw
          }
        }
      }
      prismicMarketAndLanguage: allPrismicMarketAndLanguage {
        edges {
          node {
            lang
            dataRaw
          }
        }
      }
      prismicAddUpdateShippingPage: allPrismicAddUpdateShippingPage {
        edges {
          node {
            lang
            dataRaw
          }
        }
      }
      prismicShippingInfoPage: allPrismicShippingInfoPage {
        edges {
          node {
            lang
            dataRaw
          }
        }
      }
      prismicPaymentInfoPage: allPrismicPaymentInfoPage {
        edges {
          node {
            lang
            dataRaw
          }
        }
      }
      prismicAddCardPage: allPrismicAddCardPage {
        edges {
          node {
            lang
            dataRaw
          }
        }
      }
    }
  `)

  useEffect(() => {
    if (!userLocale) return
    const buildAllPrismicData = () => {
      let prismicDataObject = {}

      Object.entries(allPrismicData).forEach(el => {
        const prismicName = el[0]
        const prismicData = el[1].edges.find(
          raw => raw.node.lang === userLocale
        )

        prismicDataObject[`${prismicName}`] = prismicData.node.dataRaw
      })
      setPrismicData(prismicDataObject)
    }

    buildAllPrismicData()
  }, [userLocale])

  useEffect(() =>{
    if(prismicData && Object.keys(prismicData).length > 0) {
      setPrismicLoading(false)
    }
  }, [prismicData])

  const value = {
    userLocale,
    prismicData,
  }
  if(prismicLoading) return (
    <Loading
      loading={prismicLoading}
      message={'Loading'} 
      showLogo={false}/>
  )
  return <Prismic.Provider value={value}>{!prismicLoading && children}</Prismic.Provider>
}

export default PrismicProvider
