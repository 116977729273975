import React, { useState } from 'react'
import { Dropdown } from 'semantic-ui-react'

import { usePrismic } from '../../context/PrismicContext'

import {
  FilterWrapper,
  ResetButton,
} from './Menu.styles'

const FilterSortMenu = ({ types, benefits, handleFilter }) => {
  const [selected, setSelected] = useState(null)
  const {
    prismicData: { prismicProductsPage },
  } = usePrismic()

  const { by_type, by_benefit, all_products } = prismicProductsPage

  const typeOptions = Object.entries(types).map(type => {
    const urlKey = type[0]
    const name = type[1].name
    return { key: urlKey, text: name, value: urlKey }
  })
  const benefitOptions = Object.entries(benefits).map(benefit => {
    const urlKey = benefit[0]
    const name = benefit[1].name
    return { key: urlKey, text: name, value: urlKey }
  })

  const handleChange = (e, { value, text }) => {
    setSelected(value)
    handleFilter(value, text)
  }

  const resetFilter = () => {
    setSelected(null)
    handleFilter('', '')
  }

  return (
    <FilterWrapper>
      {!!selected && (
        <ResetButton onClick={resetFilter}>{all_products[0].text}</ResetButton>
      )}
      <Dropdown
        item
        basic
        icon={false}
        direction="left"
        text={by_benefit[0].text}
        options={benefitOptions}
        onChange={handleChange}
        value={selected}
        selectOnBlur={false}
      />
      <span>&nbsp;|&nbsp;</span>
      <Dropdown
        item
        basic
        icon={false}
        direction="left"
        text={by_type[0].text}
        options={typeOptions}
        onChange={handleChange}
        value={selected}
        selectOnBlur={false}
      />
    </FilterWrapper>
  )
}

export default FilterSortMenu
