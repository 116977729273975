import { gql } from 'graphql-request'

export const GET_USER_TREE_NODE = gql`
  query TreeNodeFor($legacyAssociateId: Int!) {
    treeNodeFor(legacyAssociateId: $legacyAssociateId) {
      associateId
      uplineAssociateId
      orders {
        orderId
      }
      cv
      pv
      ov
      qoV
      pa
      canSee
      leg1
      leg2
      leg3
      totalOv
      rank {
        rankId
        minimumQoV
        maximumPerLeg
        legMaxPercentage
        rankName
        commission
        requiredPv
        requiredPa
      }
      # previousAmbassadorMonthlyRecord {
      #   personalVolume
      #   personallySponsoredActiveAmbassadorCount
      # }
      # currentAmbassadorMonthlyRecord {
      #   highestRank {
      #     rankId
      #     rankName
      #   }
      # }
    }
  }
`

export const GET_PROFILE = gql`
  query ($associateId: Int!) {
    associates(where: { associateId: { eq: $associateId } }) {
      associateId
      legacyAssociateId
      profileUrl
      profileImageFileName
      firstName
      lastName
      displayName
      emailAddress
      primaryPhoneNumber
      associateType
      languageCode
      associateStatus
      defaultCountry
      country {
        countryId
        countryCode
        countryName
      }
      address {
        address1
        address2
        city
        state
        zip
        countryCode
      }
    }
  }
`
