import toast from 'react-hot-toast'
// Services & Utils
import { Magento } from '../services/magento'
import {
  CartShippingAddress,
  OrderDetailsType,
  ShippingAddress,
} from '../types/CartTypes'
import { Address, Region } from '../types/contexts/StoreContextTypes'
import { parseGraphqlError } from './graphqlErrorParser'
import { Logger } from './logger'

// -------------------------------- EVENT CART HELPERS --------------------------------

export const OUT_OF_STOCK = 'OUT_OF_STOCK'

const buildEventAddress = (firstname, lastname) => ({
  firstname,
  lastname,
  street: ['100 S West Temple', ''],
  city: 'Salt Lake City',
  region: 'UT',
  postcode: '84101',
  telephone: '3854682222',
  country_code: 'US',
  save_in_address_book: false,
})

export const setEventBillingAndShippingOnCart = async (
  cartId,
  firstname,
  lastname,
  update
) => {
  const address = buildEventAddress(firstname, lastname)

  const magentoShippingObj = {
    cart_id: cartId,
    shipping_addresses: [
      {
        address,
      },
    ],
  }

  const magentoBillingObj = {
    cart_id: cartId,
    billing_address: {
      address,
    },
  }

  try {
    await Magento.Cart.setShippingAddressOnCart(magentoShippingObj)
      .then(() => Logger.log('EVENT Shipping address set!'))
      .catch(err => Logger.log(err))

    await Magento.Cart.setBillingAddressOnCart(magentoBillingObj)
      .then(() => Logger.log('EVENT Billing address set!'))
      .catch(err => Logger.log(err))
  } catch (error) {
    Logger.log(error)
  } finally {
    Logger.log('All set! Updating cart data!')
    update()
  }
}

// -------------------------------- EVENT CART HELPERS (end) --------------------------------

function buildCartAddressObject(data: Address) {
  if (typeof data !== 'object') return null
  let address = {
    ...data,
    region: getRegionCode(data.region),
    save_in_address_book: false,
  }

  // add & remove required properties
  delete address.id
  delete address.default_shipping

  return address
}

export const setBillingAndShippingAddressOnCart = async (
  cartId,
  addressData,
  setCartData
) => {
  // we are setting shipping AND billing address
  const magentoShippingObj = {
    cart_id: cartId,
    billing_address: {
      address: buildCartAddressObject(addressData),
      same_as_shipping: true,
    },
  }
  await Magento.Cart.setBillingAddressOnCart(magentoShippingObj)
    .then(setCartData)
    .then(() => toast.success('Shipping address set!'))
    .catch(err => Logger.log(err))
}

function buildUserAddressObject(data, default_shipping) {
  if (typeof data !== 'object') return null
  const {
    firstname,
    lastname,
    address1,
    address2,
    city,
    state,
    postcode,
    telephone,
    country_code,
  } = data
  const street = [address1, address2]
  const [region_id, region_code, region] = state.split(',')
  const regionObj = { region_id, region_code, region }

  return {
    firstname,
    lastname,
    street,
    city,
    region: regionObj,
    postcode,
    telephone,
    country_code,
    default_shipping,
    default_billing: default_shipping,
  }
}

export const createUserAddress = (data, isDefault, updateUser, navigate) => {
  const newShippingAddress = {
    input: buildUserAddressObject(data, isDefault),
  }

  Magento.User.createCustomerAddress(newShippingAddress)
    .then(() => toast.success('New shipping address added!'))
    .then(updateUser)
    .then(() => navigate('/shipping-info'))
}

export const updateUserAddress = (
  addressId,
  data,
  isDefault,
  updateUser,
  navigate
) => {
  const updatedShippingAddress = {
    id: addressId,
    input: buildUserAddressObject(data, isDefault),
  }
  Magento.User.updateCustomerAddress(updatedShippingAddress)
    .then(() => toast.success('Shipping address updated!'))
    .then(updateUser)
    .then(() => navigate('/shipping-info'))
}

export const getRegionCode = (region: Region | String): string => {
  if ((region as Region)?.region_code) return (region as Region).region_code
  return region as string
}

export const OrderDetailValues = ({
  subtotal_excluding_tax,
  subtotal_including_tax,
  grand_total,
  shipping,
}: OrderDetailsType) => {
  const subTotalValue = subtotal_excluding_tax?.value || 0
  const taxValue =
    subtotal_including_tax?.value - subtotal_excluding_tax?.value || 0
  const shippingValue = shipping?.amount?.value || 0
  const grandTotalValue = grand_total?.value || 0

  const getAmounts = () => ({
    subTotalText: subTotalValue.toFixed(2) ?? '',
    taxesText: taxValue.toFixed(2) ?? '',
    shippingText: shippingValue.toFixed(2) ?? '',
  })

  const getTotalWithShipping = () => {
    return (grandTotalValue + shippingValue).toFixed(2)
  }

  return {
    getAmounts,
    getTotalWithShipping,
  }
}

export const handleOutOfStock = e => {
  const graphqlResponse = parseGraphqlError(e.toString())
  const { errors, data } = graphqlResponse
  if (!errors) {
    Logger.log(e)
    return
  }

  const outOfStock = errors.some(
    ({ message }) => message === 'Some of the products are out of stock.'
  )
  if (outOfStock) return data
}

export const displayOutOfStockError = ({ items }) => {
  const outOfStockItems = items
    .filter(({ product }) => product.stock_status === OUT_OF_STOCK)
    .map(({ product }) => product.name)
  const outOfStockItemTitles = outOfStockItems.join(',')
  const message = `An item in your cart is out of stock: ${outOfStockItemTitles}`
  toast.error(message)
}

/**
 * Magento does not provide an id for the address in the cart so we have to select the closest match.
 * Compares street, postal code, city, and state and returns an exact match. If there's more than one
 * match, it returns the first
 */
export const getSelectedAddress = (
  userAddresses: Partial<ShippingAddress>[],
  cartAddress: Partial<CartShippingAddress>
) => {
  return userAddresses.find(({ street, postcode, city }) => {
    return (
      street[0] === cartAddress?.street[0] &&
      postcode === cartAddress?.postcode &&
      city === cartAddress?.city
    )
  })
}
