import React from 'react'
import { navigate } from 'gatsby'
import { Button } from 'semantic-ui-react'

import {
  Wrapper,
  IconWrapper,
  Title,
} from './PageHeader.styles'

const PageHeader = ({ children, exitRoute }) => {
  function exit() {
    if (exitRoute) navigate(exitRoute)
    else navigate(-1)
  }

  return (
    <Wrapper>
      <IconWrapper>
        <Button data-qa="goBack" icon="x" size="small" basic circular onClick={exit} />
      </IconWrapper>
      <Title data-qa="pageHeaderTitle">{children}</Title>
    </Wrapper>
  )
}

export default PageHeader
