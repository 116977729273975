import * as yup from 'yup'

// ------------------------ User Authorization ------------------------

export const signUpSchema = yup.object().shape({
  email: yup
    .string()
    .email('Please enter a valid email')
    .required('Email is required'),
  password: yup
    .string()
    .required('Password is required')
    .matches(/[a-z]/, 'Must contain at least 1 lowercase letter')
    .matches(/[A-Z]/, 'Must contain at lease 1 uppercase letter')
    .matches(/\d/, 'Must contain at least 1 digit')
    .min(8, 'Password must be at least 8 characters'),
  passwordConfirm: yup
    .string()
    .required('Confirm password is required')
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
})

export const signInSchema = yup.object().shape({
  email: yup
    .string()
    .email('Please enter a valid email')
    .required('Email is required'),
  password: yup.string().required('Password is required'),
})

export const forgotPasswordSchema = yup.object().shape({
  resetEmail: yup
    .string()
    .email('Please enter a valid email')
    .required('Email is required'),
})

export const accountEnrollmentSchema = yup.object().shape({
  referred_by: yup.string().max(30, 'Max 30 chars'),
  firstname: yup.string().required('First name is required'),
  lastname: yup.string().required('Last name is required'),
  telephone: yup
    .string()
    .required('Phone number is required')
    .length(10, '10 digit number required')
    .matches(/\d{10}/, 'Invalid entry'),
})

// ------------------------ Q Services Account Validation ------------------------

export const existingCustomer = yup.object().shape({
  customer: yup.string().required('Customer ID or Username is required'),
})

export const enterCode = yup.object().shape({
  code: yup
    .string()
    .required('Code is required')
    .matches(/^[0-9]{6}/, 'Invalid code, please try again'),
})

export const validationSchema = yup.object().shape({
  password: yup
    .string()
    .test('oneOfRequired', 'You must enter one field', function (item) {
      return this.parent.password || this.parent.email || this.parent.phone
    }),
  email: yup
    .string()
    .test('oneOfRequired', 'You must enter one field', function (item) {
      return this.parent.password || this.parent.email || this.parent.phone
    }),
  phone: yup
    .string()
    .test('oneOfRequired', 'You must enter one field', function (item) {
      return this.parent.password || this.parent.email || this.parent.phone
    }),
})

// ------------------------ Checkout ------------------------

export const shippingAddressSchema = yup.object().shape({
  firstname: yup.string().required('First name required').max(25, 'Too long'),
  lastname: yup.string().required('Last name required').max(25, 'Too long'),
  address1: yup.string().required('Address1 required').max(30, 'Too long'),
  address2: yup.string().max(30, 'Too long'),
  city: yup.string().required('City required').max(30, 'Too long'),
  state: yup.string().required('State required'),
  postcode: yup
    .string()
    .required('Zip code required')
    .matches(/^[0-9]{5}/, '5 digit zip code')
    .length(5, '5 digit zip code'),
  country_code: yup
    .string()
    .required()
    .matches(/^[A-Z]{2}/),
  telephone: yup
    .string()
    .required('Phone number required')
    .matches(/^[0-9]{10}/, '10 digit format (no dashes/parenthesis)')
    .length(10, '10 digit format (no dashes/parenthesis)'),
})

export const addCouponCode = yup.object().shape({
  code: yup.string().max(20, 'Too long'),
})
