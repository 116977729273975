import { GraphQLClient } from 'graphql-request'
import {
  BASIC_INFO,
  CHECK_IF_USER_EXISTS,
  CREATE_CUSTOMER_CART,
  GET_ALL_CATEGORIES_WITH_PRODUCTS,
  GET_ALL_PRODUCTS,
  GET_AVAILABLE_COUNTRIES,
  GET_CATEGORIES,
  GET_COUNTRY_DATA_BY_ID,
  GET_CUSTOMER_ORDERS,
  GET_LANDING_PAGE_STORE_DATA,
  GET_ORDER,
  GET_ORDERS_PAGE_INFO,
  GET_SINGLE_CATEGORY_PRODUCTS,
  GET_SINGLE_PRODUCT,
  ORDER_HISTORY,
} from './graphql/magento/queries'
import {
  ADD_BUNDLE_PRODUCTS_TO_CART,
  ADD_PRODUCTS_TO_CART,
  APPLY_COUPON_TO_CART,
  CLEAR_CUSTOMER_CART,
  CREATE_CUSTOMER_ADDRESS,
  CREATE_CUSTOMER,
  CREATE_EMPTY_CART,
  DELETE_CUSTOMER_ADDRESS,
  GENERATE_CUSTOMER_TOKEN,
  GET_FIREBASE_TOKEN,
  GET_MAGENTO_TOKEN_FROM_FIREBASE_TOKEN,
  PLACE_ORDER,
  REMOVE_COUPON,
  REMOVE_PRODUCT_FROM_CART,
  REORDER_ITEMS,
  SET_BILLING_ADDRESS_ON_CART,
  SET_PAYMENT_METHOD,
  SET_SHIPPING_ADDRESSES_ON_CART,
  SET_SHIPPING_METHOD,
  UPDATE_CART_ITEMS,
  UPDATE_CUSTOMER_ADDRESS,
  UPDATE_CUSTOMER_NAME,
} from './graphql/magento/mutations'
import environment from '../utils/environment'

const endpoint = environment.MAGENTO_API_URL

const client = new GraphQLClient(`${endpoint}`)

const User = {
  /**
   * Return Firebase token from input data
   *
   * @param {object} variables `email`, `password`
   * @returns `status`, `message`, `firebase_token`
   */
  getFirebaseToken: async variables =>
    await client.request(GET_FIREBASE_TOKEN, variables),
  /**
   * Returns Magneto token, if `isNewCustomer: 1` customer will be created
   *
   * @param {object} variables `jwt_token`, `first_name`, `last_name`, `associate_id`
   * @returns {object} `status`, `message`, `customer_token`, `isNewCustomer`
   */
  getMagentoTokenFromFirebaseToken: async variables =>
    await client.request(GET_MAGENTO_TOKEN_FROM_FIREBASE_TOKEN, variables),
  /**
   * Update Magento customer name from data returned from DS
   *
   * @param {object} variables `firstname`, `lastname`
   * @returns {object} `email`, `firstname`, `lastname`
   */
  updateCustomerName: async variables =>
    await client.request(UPDATE_CUSTOMER_NAME, variables),
  /**
   * Adds a new user to Magento
   *
   * @param {Object} variables `firstname`, `lastname`, `email`, `password`
   * @returns {String} **email** - `createCustomer.customer`
   */
  addUser: async variables => await client.request(CREATE_CUSTOMER, variables),
  /**
   * Gets an access token for calling the Magento API
   *
   * @param {Object} variables `email`, `password`
   * @returns {String} **token** - `generateCustomerToken.token`
   */
  getToken: async variables =>
    await client.request(GENERATE_CUSTOMER_TOKEN, variables),
  /**
   * Sets and attaches the `authorization` header to all subsequent requests
   *
   * @param {string} token customer token
   */
  setToken: token => client.setHeader('authorization', `Bearer ${token}`),
  /**
   * Sets the store view the customer will view products from
   * @param {string} storeViewCode
   */
  setStoreView: storeViewCode => client.setHeader('store', `${storeViewCode}`),
  unSetToken: () => client.setHeaders({}),
  /**
   * Check if provided email is "available" in Magento, ie. an account exists
   *
   * @param {String} email The email used for Firebase login
   * @returns {Boolean} **boolean** - `isEmailAvailable.is_email_available`
   */
  isEmailAvailable: async email =>
    await client.request(CHECK_IF_USER_EXISTS, { email }),
  basicInformation: async () => await client.request(BASIC_INFO),
  orderHistory: async variables =>
    await client.request(ORDER_HISTORY, variables),
  getCustomerOrders: async () => await client.request(GET_CUSTOMER_ORDERS),
  getCustomerOrderById: async variables =>
    await client.request(GET_ORDER, variables),
  getOrdersPageInfo: async () => await client.request(GET_ORDERS_PAGE_INFO),
  createCustomerAddress: async data =>
    await client.request(CREATE_CUSTOMER_ADDRESS, data),
  updateCustomerAddress: async data =>
    await client.request(UPDATE_CUSTOMER_ADDRESS, data),
  deleteCustomerAddress: async id =>
    await client.request(DELETE_CUSTOMER_ADDRESS, id),
}

const Store = {
  getAvailableCountries: async () =>
    await client.request(GET_AVAILABLE_COUNTRIES),
  getCountryData: async id => await client.request(GET_COUNTRY_DATA_BY_ID, id),
  getAllCategoriesWithProducts: async () => {
    const { categories } = await client.request(
      GET_ALL_CATEGORIES_WITH_PRODUCTS
    )
    return categories.items[0].children
  },
  getLandingPageData: async () =>
    await client.request(GET_LANDING_PAGE_STORE_DATA),
  getSingleProductByUrlKey: async data =>
    await client.request(GET_SINGLE_PRODUCT, data),
  getSingleCategoryData: async data =>
    await client.request(GET_SINGLE_CATEGORY_PRODUCTS, data),
  getAllProducts: async () => await client.request(GET_ALL_PRODUCTS),
  getCategories: async () => await client.request(GET_CATEGORIES),
}

const Cart = {
  /**
   * Creates a GUEST cart
   *
   * @returns {String} cart ID
   */
  createEmptyCart: async () => await client.request(CREATE_EMPTY_CART),
  /**
   * Returns the active cart for the logged-in customer. If the cart does not exist, the query creates one
   *
   * @returns {Promise<object>} `customerCart`
   */
  getActiveCartOrCreateNew: async () =>
    await client.request(CREATE_CUSTOMER_CART),
  clearCustomerCart: async cartUid =>
    await client.request(CLEAR_CUSTOMER_CART, cartUid),
  reorderItems: async orderNumber =>
    await client.request(REORDER_ITEMS, orderNumber),
  /**
   *
   * @param {object} data customer cart ID, cart items array
   * @returns {Promise<object>} `addProductsToCart.cart`
   */
  addProductsToCart: async data =>
    await client.request(ADD_PRODUCTS_TO_CART, data),
  addBundleProductsToCart: async data =>
    await client.request(ADD_BUNDLE_PRODUCTS_TO_CART, data),
  /**
   *
   * @param {object} data `cart_id` and `cart_items` array with the cart item IDs (uid) and quantity of each item
   * @returns {Promise<object>} `updateCartItems.cart`
   */
  updateItemsInCart: async data =>
    await client.request(UPDATE_CART_ITEMS, data),
  /**
   * Deletes the entire quantity of a specified item from the cart. If you remove all items from the cart, the cart continues to exist
   *
   * @param {object} data `cart_id`, `cart_item_uid`
   * @returns {Promise<object>} `removeItemFromCart.cart`
   */
  removeItemFromCart: async data =>
    await client.request(REMOVE_PRODUCT_FROM_CART, data),
  setShippingAddressOnCart: async data => {
    return await client.request(SET_SHIPPING_ADDRESSES_ON_CART, data)
  },
  setBillingAddressOnCart: async data =>
    await client.request(SET_BILLING_ADDRESS_ON_CART, data),
  setShippingMethodOnCart: async data =>
    await client.request(SET_SHIPPING_METHOD, data),
  setPaymentMethodOnCart: async data =>
    await client.request(SET_PAYMENT_METHOD, data),
  placeOrder: async data => await client.request(PLACE_ORDER, data),
  applyCouponToCart: async data =>
    await client.request(APPLY_COUPON_TO_CART, data),
  removeCouponFromCart: async data => await client.request(REMOVE_COUPON, data),
}

export const Magento = { User, Store, Cart }
