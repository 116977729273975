import React, { useState } from 'react'
import { navigate } from 'gatsby'
import { Button, Radio } from 'semantic-ui-react'
// Components
import { ProductImages } from '..'
// Styles
import { Container, StyledSpan } from './ProductSummary.styles'
import { useAuthContext } from '../../context/AuthContext'

const ConfigurableProduct = ({
  name,
  image,
  configurableOptions,
  setSelected,
  regularPrice,
  finalPrice,
  pv,
}) => {
  const [checked, setChecked] = useState(null)
  const { isAmbassador } = useAuthContext()

  const configObjArr = Object.values(configurableOptions)
  const optionImage = configurableOptions?.[checked]?.image

  const handleChange = (e, { value }) => {
    setChecked(value)
    setSelected(value)
  }

  return (
    <>
      <Container padding="1em">
        <Button
          icon="x"
          size="small"
          basic
          circular
          onClick={() => navigate(-1)}
        />
      </Container>
      <Container justify="center" align="center" maxWidth="800px">
        <Container justify="flex-end" align="center">
          <ProductImages
            mediaGallery={[]}
            image={!!checked ? optionImage : image}
          />
        </Container>
        <Container column>
          <div style={{ marginBottom: '10px' }}>
            <StyledSpan isTitle>{name}</StyledSpan>
          </div>
          <div style={{ marginBottom: '10px' }}>
            {isAmbassador && regularPrice !== finalPrice && (
              <StyledSpan lineThru>{`$${
                regularPrice?.toFixed(2) ?? ''
              }`}</StyledSpan>
            )}
            <StyledSpan>{`$${finalPrice?.toFixed(2) ?? ''}`}</StyledSpan>
          </div>
          {isAmbassador && (
            <div>
              <StyledSpan pv>{`PV ${pv}`}</StyledSpan>
            </div>
          )}
          {configObjArr.length > 0 && (
            <div
              style={{
                marginTop: '10px',
                display: 'flex',
                flexFlow: 'column nowrap',
              }}
            >
              {configObjArr.map(option => (
                <Radio
                  style={{ marginTop: '5px' }}
                  key={option.uid}
                  label={option.label}
                  name="optionsGroup"
                  value={option.uid}
                  checked={checked === option.uid}
                  onChange={handleChange}
                />
              ))}
            </div>
          )}
        </Container>
      </Container>
    </>
  )
}

export default ConfigurableProduct
