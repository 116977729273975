import React, { useEffect, useState } from 'react'
// Context
import { useStore } from '../../context/StoreContext'
import { useAuthContext } from '../../context/AuthContext'
// Styles
import {
  ProductCostInfo,
  ProductCostInfoWrapper,
  ProductCount,
  ProductImage,
  ProductItem,
  ProductTitle,
  StyledSpan,
} from './Orders.styles'
import { number } from 'prop-types'

const tempImageUrl =
  'https://react.semantic-ui.com/images/wireframe/square-image.png'

const OrderItem = ({
  product_name,
  product_sale_price,
  product_url_key,
  quantity_ordered,
  returnPv,
  selected_options,
  orderNumber,
  product_sku,
}) => {
  const [product, setProduct] = useState(null)
  const { isAmbassador } = useAuthContext()
  const { storeData, isLoadingStore } = useStore()

  useEffect(() => {
    if (isLoadingStore) return
    setProduct(getProductData())
  }, [isLoadingStore])

  const getProductData = () => {
    const productData = storeData['all-products']?.products?.find(
      product => product.url_key === product_url_key
    )
    returnPv(prev => prev + Number(productData?.pv ?? 'n/a'))
    return productData
  }

  const regularPrice =
    product?.price_range?.maximum_price?.regular_price?.value?.toFixed(2) ?? ''
  const finalPrice = product_sale_price?.value?.toFixed(2) ?? ''

  const productName = selected_options.length
    ? `${product_name} - ${selected_options[0].value}`
    : product_name

  const hasProductImage = product?.image?.url

  return (
    <ProductItem>
      <ProductImage data-qa={`order-${orderNumber}-image-${product_sku}`} src={hasProductImage || tempImageUrl} />
      <ProductTitle data-qa={`order-${orderNumber}-title-${product_sku}`} bold>{productName}</ProductTitle>
      <ProductCount data-qa={`order-${orderNumber}-quantity-${product_sku}`}>{quantity_ordered}</ProductCount>
      <ProductCostInfoWrapper>
        <ProductCostInfo>
          <StyledSpan data-qa={`order-${orderNumber}-regularPrice-${product_sku}`} lineThru>${regularPrice}</StyledSpan>

          <StyledSpan data-qa={`order-${orderNumber}-finalPrice-${product_sku}`} width="100%" textAlign="right">
            ${finalPrice}
          </StyledSpan>
        </ProductCostInfo>
        {isAmbassador && (
          <ProductCostInfo>
            <StyledSpan bold>PV</StyledSpan>

            <StyledSpan data-qa={`order-${orderNumber}-pv-${product_sku}`}>{product?.pv}</StyledSpan>
          </ProductCostInfo>
        )}
      </ProductCostInfoWrapper>
    </ProductItem>
  )
}

export default React.memo(OrderItem)
