import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import { Image } from 'semantic-ui-react'

export const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.black};
  max-height: 222px;
  @media ${({ theme }) => theme.device.sm} {
    flex-flow: row;
    max-height: 203px;
  }
`

export const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: ${props => props.justify ?? 'center'};
  align-items: ${props => props.align ?? 'center'};
  width: 100%;
  ${props =>
    props.row &&
    css`
      flex-flow: row nowrap;
      margin-bottom: 2em;
    `}

  a:link,
  a:visited {
    color: ${({ theme }) => theme.colors.white};
  }

  @media ${({ theme }) => theme.device.sm} {
    align-items: center;
  }
`

export const StyledLink = styled(Link)`
  &&& {
    color: ${({ theme }) => theme.colors.white};
    margin: 0 0 5px 5px;
    font-weight: ${props => props.weight ?? 'none'};
    font-size: ${props => props.size ?? '1rem'};
  }
`

export const Logo = styled(Image)`
  &&& {
    width: auto;
    height: 80px;
  }
`
